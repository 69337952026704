import { EncryptStorage } from 'encrypt-storage';

export const exponeaCapture = (variables, type) => {
  if (!getAllowed().includes('marketing')) {
    return;
  }

  if (process.env.REACT_APP_EXPONEA != 'false' && window?.exponea) {
    window.exponea.initialize({ token: process.env.REACT_APP_EXPONEA });
    window.exponea.start();
  } else return;

  const isDevEnv = process.env.REACT_APP_API_BASE.includes('localhost');

  const storage = isDevEnv
    ? sessionStorage
    : EncryptStorage(process.env.REACT_APP_CLIENT_ID, {
      storageType: 'sessionStorage',
    });
  var user = storage.getItem('session')?.user;
  if (user) {
    window.exponea.identify({
      email_id: user.email,
      mobile_phone: user.phoneNumber,
    });
  }

  variables.push({ name: 'location', value: window.location.href });
  variables.push({ name: 'domain', value: window.location.origin });
  const logObject = {};

  variables.forEach(({ name, value }, index) => {
    logObject[name || `variable${index + 1}`] = value;
  });

  if (window.exponea) {
    if (type === 'booking_start') {
      window.exponea.track('booking', logObject);
      // console.log(type, logObject);
    }

    if (type === 'booking') {
      // console.log('logObject', logObject);
      const paymentType = logObject['registration type'];
      let bookinType = 'Paysera';
      if (paymentType === 'Paysera') {
        bookinType = 'Paysera';
      } else if (paymentType === 'PSDFWithCoupons') {
        bookinType = 'Paysera';
      } else if (paymentType === 'Social') {
        bookinType = 'Private insurance';
      } else if (paymentType === 'PSDF') {
        bookinType = 'Paid by NHS';
      } else if (paymentType === 'Coupons') {
        bookinType = 'Voucher';
      }
      const bookingObj = {
        booking_type: 'Web-booking',
        booking_payment_type: bookinType,
        booking_appointment_id: logObject.booking_appointment_id,
        booking_booking_id: logObject.booking_booking_id,
        booking_payment_gateway_id: logObject.booking_booking_id,
        purchase_total_cost: logObject.purchase_total_cost,
        boking_status_datetime: new Date(),
        time: new Date(),
      };
      const combinedObject = { ...logObject, ...bookingObj };

      window.exponea.identify({
        email_id: logObject['email'],
        mobile_phone: logObject['mobile_phone'],
      });

      window.exponea.track(type, combinedObject);
      // console.log(type, combinedObject);
    }

    if (type === 'view_item' || type === 'booking_item') {
      if (logObject.category_1 || logObject.category_2) {
        logObject.categories_path = generateCategoriesPath(logObject).value;
        window.exponea.track(type, logObject);
        // console.log(type, logObject);
      }
    }
  }
};

function generateCategoriesPath(obj) {
  const categoriesPath = { name: 'categories_path', value: 'Home' };
  const arrayFromObject = Object.entries(obj).map(([name, value]) => ({
    name,
    value,
  }));

  arrayFromObject
    .filter(
      (obj) => obj.name.startsWith('category_') && obj.name !== 'category_id',
    )
    .forEach((obj) => {
      const categoryValue =
        typeof obj.value === 'function' ? obj.value() : obj.value;
      if (categoryValue) {
        if (categoriesPath.value) {
          categoriesPath.value += ' > ';
        }
        categoriesPath.value += categoryValue;
      }
    });

  return categoriesPath;
}

const getAllowed = () => {
  const ls = localStorage.getItem('cookies');
  return ls ? ls.split(',') : [];
};
