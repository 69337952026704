import { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import { CachePolicies, Provider as FetchProvider } from 'use-http';
import PrivateRoute from '../constants/PrivateRoute';
import { CartProvider } from '../context/CartContext';
import { SettingsProvider } from '../context/SettingsContext';
import { TransferProvider } from '../context/TransferContext';
import { UserProvider } from '../context/UserContext';
import RouterFbClick from '../hooks/routerFbClick';
import useToken from '../hooks/useToken';
import CartPage from '../pages/CartPage';
import CategoriesPage from '../pages/CategoriesPage';
import CouponCartPage from '../pages/CouponCartPage/CouponCartPage';
import CouponConfirmedPage from '../pages/CouponsPage/CouponConfirmedPage/CouponConfirmedPage';
import Coupons from '../pages/CouponsPage/Coupons';
import SmsCodeConfirmation from '../pages/SelfServicePage/ConfirmIdentity/SmsCodeConfirmation';
import FinishRegistrationPage from '../pages/SelfServicePage/FinishRegistrationPage/FinishRegistrationPage';
import IdentificationConfirmPage from '../pages/SelfServicePage/IdentificationConfirmPage/IdentificationConfirmPage';
import MSignatureLogin from '../pages/SelfServicePage/LoginOptions/MSignatureLogin';
import SmartIdLogin from '../pages/SelfServicePage/LoginOptions/SmartIdLogin';
import PasswordResetFormPage from '../pages/SelfServicePage/PasswordReset/PasswordResetForm/PasswordResetFormPage';
import PasswordResetPage from '../pages/SelfServicePage/PasswordReset/PasswordResetPage';
import RegisterPage from '../pages/SelfServicePage/Registration/RegisterPage';
import RegisterTermsPage from '../pages/SelfServicePage/Registration/RegisterTermsPage';
import SelfService from '../pages/SelfServicePage/SelfServicePage';
import AccountCredentialsEdit from '../pages/SelfServicePage/UserAccountPage/AccountManagment/AccountCredentialsEdit/AccountCredentialsEdit';
import AccountInfoEdit from '../pages/SelfServicePage/UserAccountPage/AccountManagment/AccountInfoEdit/AccountInfoEdit';
import Agreements from '../pages/SelfServicePage/UserAccountPage/AccountManagment/Agreements/Agreements';
import AuthorizedPersons from '../pages/SelfServicePage/UserAccountPage/AccountManagment/AuthorizedPersons/AuthorizedPersons';
import AuthorizedPersonsConfirmation from '../pages/SelfServicePage/UserAccountPage/AccountManagment/AuthorizedPersonsConfirmations/AuthorizedPersonsConfirmation';
import AmisSync from '../pages/SelfServicePage/UserAccountPage/Administration/AmisSync/AmisSync';
import AmisSyncLogDetails from '../pages/SelfServicePage/UserAccountPage/Administration/AmisSyncLogs/AmisSyncLogDetails';
import AmisSyncLogs from '../pages/SelfServicePage/UserAccountPage/Administration/AmisSyncLogs/AmisSyncLogs';
import ConsentLogDetails from '../pages/SelfServicePage/UserAccountPage/ConsentsChanges/ConsentLogDetails/ConsentLogDetails';
import ConsentLogs from '../pages/SelfServicePage/UserAccountPage/ConsentsChanges/ConsentLogs';
import FailedRegistrations from '../pages/SelfServicePage/UserAccountPage/FailedRegistrations/FailedRegistrations';
import LogDetails from '../pages/SelfServicePage/UserAccountPage/Logs/LogDetails/LogDetails';
import Logs from '../pages/SelfServicePage/UserAccountPage/Logs/Logs';
import PopupWindow from '../pages/SelfServicePage/UserAccountPage/Administration/PopupWindow/PopupWindow';
import RegistrationList from '../pages/SelfServicePage/UserAccountPage/Registrations/RegistrationList/RegistrationList';
import RegistrationTab from '../pages/SelfServicePage/UserAccountPage/Registrations/RegistrationTab/RegistrationTab';
import UserAccountPage from '../pages/SelfServicePage/UserAccountPage/UserAccountPage';
import VisitDetailsComponent from '../pages/SelfServicePage/UserAccountPage/Visits/VisitDetailsComponent';
import VisitsComponent from '../pages/SelfServicePage/UserAccountPage/Visits/VisitsComponent';
import VisitE025Details from '../pages/SelfServicePage/UserAccountPage/VisitsSubCategories/E025/VisitE025Details';
import VisitE025List from '../pages/SelfServicePage/UserAccountPage/VisitsSubCategories/E025/VisitE025List';
import InstrumentalResearches from '../pages/SelfServicePage/UserAccountPage/VisitsSubCategories/InstrumentalResearches/InstrumentalResearches';
import InstrumentalResearchesTab from '../pages/SelfServicePage/UserAccountPage/VisitsSubCategories/InstrumentalResearches/InstrumentalResearchesTab';
import LabaratoryResearches from '../pages/SelfServicePage/UserAccountPage/VisitsSubCategories/LabaratoryResearches/LabaratoryResearches';
import LabaratoryResearchesTab from '../pages/SelfServicePage/UserAccountPage/VisitsSubCategories/LabaratoryResearches/LabaratoryResearchesTab';
import OtherVisits from '../pages/SelfServicePage/UserAccountPage/VisitsSubCategories/OtherVisits/OtherVisits';
import PrescriptionsComponent from '../pages/SelfServicePage/UserAccountPage/VisitsSubCategories/Prescriptions/PrescriptionsComponent';
import PrescriptionTabComponent from '../pages/SelfServicePage/UserAccountPage/VisitsSubCategories/Prescriptions/PrescriptionTabComponent';
import ReferralsComponent from '../pages/SelfServicePage/UserAccountPage/VisitsSubCategories/Referrals/ReferralsComponent';
import ReferralTabComponent from '../pages/SelfServicePage/UserAccountPage/VisitsSubCategories/Referrals/ReferralTabComponent';
import VaccinationDetails from '../pages/SelfServicePage/UserAccountPage/VisitsSubCategories/Vaccinations/VaccinationDetails';
import VaccinationsList from '../pages/SelfServicePage/UserAccountPage/VisitsSubCategories/Vaccinations/VaccinationsList';
import ServicePage from '../pages/ServicePage';
import ServicesPage from '../pages/ServicesPage';
import TransferPreviewPage from '../pages/TransferPreviewPage';
import TransferRequestPage from '../pages/TransferRequestPage';
import TransferSuccessPage from '../pages/TransferSuccessPage';
import CookiesConsent from '../shared/CookiesConsent';
import ErrorModal from '../shared/ErrorModal';
import Footer from '../shared/Footer';
import GoogleAnalytics from '../shared/GoogleAnalytics';
import Header from '../shared/Header';
import PaymentRedirectModal from '../shared/PaymentRedirectModal';
import ScrollToTop from '../shared/ScrollToTop';
import TimeSlotSession from '../shared/TimeSlotSession';
import UserSidebar from '../shared/UserSidebar/UserSidebar';
import IdleTimerContainer from './IdleTimerContainer';
import PopUp from '../hooks/popUp';
import PatientInfo from '../pages/SelfServicePage/UserAccountPage/Administration/PatientInfo/PatientInfo';
import LocalRegistration from '../pages/SelfServicePage/UserAccountPage/LocalRegistration/LocalRegistration';
import LocalRegistrationList from '../pages/SelfServicePage/UserAccountPage/LocalRegistration/LocalRegistrationList.js';
import LocalRegistrationDetails from '../pages/SelfServicePage/UserAccountPage/LocalRegistration/LocalRegistrationDetails';
import AmisRequestLogs from '../pages/SelfServicePage/UserAccountPage/Administration/AmisRequests/AmisRequestLogs';
import DoctorInformation from '../pages/SelfServicePage/UserAccountPage/Administration/DoctorInformation/DoctorInformation';
import Combined from '../pages/CombinedPage/CombinedPage';
import CombinedServicesCheckout from '../pages/CombinedPage/CombinedServicesCheckout/CombinedServicesCheckout';
import CombinedServicesCompleted from '../pages/CombinedPage/CombinedServicesCompleted/CombinedServicesCompleted';
import ComplexServiceList from '../pages/SelfServicePage/UserAccountPage/VisitsSubCategories/ComplexService/ComplexServiceList';
import PathologyResearches from '../pages/SelfServicePage/UserAccountPage/VisitsSubCategories/PathologyResearches/PathologyResearches';
import PathologyResearchesTab from '../pages/SelfServicePage/UserAccountPage/VisitsSubCategories/PathologyResearches/PathologyResearchesTab';
import ComplexServiceDetails from '../pages/SelfServicePage/UserAccountPage/VisitsSubCategories/ComplexService/ComplexServiceDetails';
import GoogleAnalyticsTag from '../shared/GoogleAnalyticsTag/GoogleAnalyticsTag';
import ConsentForms from '../pages/SelfServicePage/UserAccountPage/AccountManagment/ConsentForms/ConsentForms';
import ServiceInformation from '../pages/SelfServicePage/UserAccountPage/Administration/ServiceInformation/ServiceInformation.js';
import CouponHistory from '../pages/SelfServicePage/UserAccountPage/CouponHistory/CouponHistory.js';
import CouponHistoryDetails from '../pages/SelfServicePage/UserAccountPage/CouponHistory/CouponHistoryDetails.js';
import SpecialistRestrictions from '../pages/SelfServicePage/UserAccountPage/Administration/SpecialistRestrictions/SpecialistRestrictions.js';

function App() {
  const { handleToken } = useToken();
  const [showError] = useState(false); // setShowError
  const history = useHistory();
  const [showHeaders, setShowHeaders] = useState(true);
  const pathname = window.location.pathname;
  useEffect(() => {
    if (pathname.includes('/widget')) {
      setShowHeaders(false);
    }
  }, [history, pathname]);

  useEffect(() => {
    // Set global variable based on environment
    window.exponeaToken = process.env.REACT_APP_EXPONEA;
    // eslint-disable-next-line no-undef
    initializeScript();
  }, []);

  return (
    <FetchProvider
      url={process.env.REACT_APP_API_BASE}
      options={{
        cachePolicy: CachePolicies.NO_CACHE,
        headers: {
          'Access-Control-Allow-Origin': `${process.env.REACT_APP_BASE_ADDRESS}`,
        },
        interceptors: {
          request: async ({ options }) => {
            const token = await handleToken();
            options.headers.Authorization = `Bearer ${token}`;
            return options;
          },
        },
      }}
    >
      <UserProvider>
        <SettingsProvider>
          <CartProvider>
            <TransferProvider>
              <Router>
                <GoogleAnalytics>
                  <GoogleAnalyticsTag>
                    <RouterFbClick />
                    <PopUp />
                    <ScrollToTop />
                    <Header show={showHeaders}>
                      <div
                        style={{
                          color: '#333',
                          backgroundColor: '#f3f3f3',
                          padding: 20,
                          borderRadius: 16,
                          marginLeft: 20,
                          fontSize: 12,
                          maxWidth: 300,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      ></div>
                    </Header>
                    <TimeSlotSession />
                    <IdleTimerContainer>
                      <Switch>
                        <Route path="/" exact>
                          <CategoriesPage />
                        </Route>

                        <Route path="/services/:categoryId/:id" exact>
                          <ServicesPage />
                        </Route>

                        <Route path="/order/:workplaceId/:serviceId" exact>
                          <ServicePage />
                        </Route>

                        <Route
                          path="/order/:workplaceId/:serviceId/:timeSlotId/:isPaid"
                          exact
                        >
                          <ServicePage />
                        </Route>

                        <Route path="/checkout" exact>
                          <CartPage />
                        </Route>

                        <Route path="/couponcheckout" exact>
                          <CouponCartPage />
                        </Route>

                        <Route path="/selfservice" exact>
                          <SelfService />
                        </Route>

                        <PrivateRoute
                          path="/account"
                          components={[UserSidebar, UserAccountPage]}
                          exact
                        />

                        <PrivateRoute
                          path="/account/edit"
                          components={[UserSidebar, AccountInfoEdit]}
                          exact
                        />

                        <PrivateRoute
                          path="/account/manage-agreements"
                          components={[UserSidebar, Agreements]}
                          exact
                        />

                        <PrivateRoute
                          path="/account/manage-agreements/:success"
                          components={[UserSidebar, Agreements]}
                          exact
                        />

                        <PrivateRoute
                          path="/account/manage-authorized-persons"
                          components={[UserSidebar, AuthorizedPersons]}
                          exact
                        />

                        <PrivateRoute
                          path="/account/authorized-persons-confirmation"
                          components={[AuthorizedPersonsConfirmation]}
                        />

                        <PrivateRoute
                          path="/account/change-password"
                          components={[UserSidebar, AccountCredentialsEdit]}
                          exact
                        />

                        <PrivateRoute
                          path="/account/visits"
                          components={[UserSidebar, VisitsComponent]}
                          exact
                        />

                        <Route path="/kompleksines-paslaugos" exact>
                          <Combined />
                        </Route>

                        <Route path="/kompleksines-paslaugos/checkout" exact>
                          <CombinedServicesCheckout />
                        </Route>

                        <Route path="/kompleksines-paslaugos/completed" exact>
                          <CombinedServicesCompleted />
                        </Route>
                        <Route
                          path="/kompleksines-paslaugos/completed/:orderId"
                          exact
                        >
                          <CombinedServicesCompleted />
                        </Route>

                        <PrivateRoute
                          path="/account/visit-details"
                          components={[UserSidebar, VisitDetailsComponent]}
                          exact
                        />

                        <PrivateRoute
                          path="/account/instrumental-researches"
                          components={[UserSidebar, InstrumentalResearches]}
                          exact
                        />

                        <PrivateRoute
                          path="/account/instrumental-research"
                          components={[UserSidebar, InstrumentalResearchesTab]}
                          exact
                        />

                        <PrivateRoute
                          path="/account/labaratory-researches"
                          components={[UserSidebar, LabaratoryResearches]}
                          exact
                        />

                        <PrivateRoute
                          path="/account/labaratory-research"
                          components={[UserSidebar, LabaratoryResearchesTab]}
                          exact
                        />

                        <PrivateRoute
                          path="/account/prescriptions"
                          components={[UserSidebar, PrescriptionsComponent]}
                          exact
                        />

                        <PrivateRoute
                          path="/account/prescription"
                          components={[UserSidebar, PrescriptionTabComponent]}
                          exact
                        />

                        <PrivateRoute
                          path="/account/vaccinations"
                          components={[UserSidebar, VaccinationsList]}
                          exact
                        />

                        <PrivateRoute
                          path="/account/vaccination"
                          components={[UserSidebar, VaccinationDetails]}
                          exact
                        />

                        <PrivateRoute
                          path="/account/referrals"
                          components={[UserSidebar, ReferralsComponent]}
                          exact
                        />

                        <PrivateRoute
                          path="/account/referral"
                          components={[UserSidebar, ReferralTabComponent]}
                          exact
                        />

                        <PrivateRoute
                          path="/account/E025"
                          components={[UserSidebar, VisitE025List]}
                          exact
                        />

                        <PrivateRoute
                          path="/account/E025-details"
                          components={[UserSidebar, VisitE025Details]}
                          exact
                        />
                        <PrivateRoute
                          path="/account/complex-services"
                          components={[UserSidebar, ComplexServiceList]}
                          exact
                        />
                        <PrivateRoute
                          path="/account/pathology-researches"
                          components={[UserSidebar, PathologyResearches]}
                          exact
                        />

                        <PrivateRoute
                          path="/account/pathology-research"
                          components={[UserSidebar, PathologyResearchesTab]}
                          exact
                        />
                        <PrivateRoute
                          path="/account/complex-service"
                          components={[UserSidebar, ComplexServiceDetails]}
                          exact
                        />

                        <PrivateRoute
                          path="/account/registrations"
                          components={[UserSidebar, RegistrationList]}
                          exact
                        />

                        <PrivateRoute
                          path="/account/registration"
                          components={[UserSidebar, RegistrationTab]}
                          exact
                        />

                        <PrivateRoute
                          path="/account/failed-registrations"
                          components={[UserSidebar, FailedRegistrations]}
                          exact
                        />

                        <PrivateRoute
                          path="/account/other-visits"
                          components={[UserSidebar, OtherVisits]}
                          exact
                        />

                        <PrivateRoute
                          path="/logs"
                          components={[UserSidebar, Logs]}
                          exact
                        />

                        <PrivateRoute
                          path="/popup"
                          components={[UserSidebar, PopupWindow]}
                          exact
                        />

                        <PrivateRoute
                          path="/patientInfo"
                          components={[UserSidebar, PatientInfo]}
                          exact
                        />

                        <PrivateRoute
                          path="/consentLogs"
                          components={[UserSidebar, ConsentLogs]}
                          exact
                        />

                        <PrivateRoute
                          path="/doctorInformation"
                          components={[UserSidebar, DoctorInformation]}
                          exact
                        />

                        <PrivateRoute
                          path="/serviceInformation"
                          components={[UserSidebar, ServiceInformation]}
                          exact
                        />

                        <PrivateRoute
                          path="/sync"
                          components={[UserSidebar, AmisSync]}
                          exact
                        />

                        <PrivateRoute
                          path="/log/details"
                          components={[UserSidebar, LogDetails]}
                          exact
                        />

                        <PrivateRoute
                          path="/consentLog/details"
                          components={[UserSidebar, ConsentLogDetails]}
                          exact
                        />

                        <PrivateRoute
                          path="/registration/local"
                          components={[UserSidebar, LocalRegistration]}
                          exact
                        />

                        <PrivateRoute
                          path="/registration/local/list"
                          components={[UserSidebar, LocalRegistrationList]}
                          exact
                        />

                        <PrivateRoute
                          path="/registration/local/details"
                          components={[UserSidebar, LocalRegistrationDetails]}
                          exact
                        />

                        <PrivateRoute
                          path="/syncLogs"
                          components={[UserSidebar, AmisSyncLogs]}
                          exact
                        />

                        <PrivateRoute
                          path="/amis/requests"
                          components={[UserSidebar, AmisRequestLogs]}
                          exact
                        />

                        <PrivateRoute
                          path="/syncLog/details"
                          components={[UserSidebar, AmisSyncLogDetails]}
                          exact
                        />
                        <PrivateRoute
                          path="/account/consent-forms"
                          components={[UserSidebar, ConsentForms]}
                          exact
                        />

                        <PrivateRoute
                          path="/account/user-coupons"
                          components={[UserSidebar, CouponHistory]}
                          exact
                        />

                        <PrivateRoute
                          path="/admin/restrictions"
                          components={[UserSidebar, SpecialistRestrictions]}
                          exact
                        />

                        <PrivateRoute
                          path="/account/user-coupon/details"
                          components={[UserSidebar, CouponHistoryDetails]}
                          exact
                        />

                        <Route path="/login/smartid" exact>
                          <SmartIdLogin />
                        </Route>

                        <Route path="/login/msignature" exact>
                          <MSignatureLogin />
                        </Route>

                        <Route path="/verify/phone" exact>
                          <SmsCodeConfirmation />
                        </Route>

                        <Route path="/dovanu-kuponai" exact>
                          <Coupons />
                        </Route>

                        <Route path="/coupon/checkout" exact>
                          <CouponConfirmedPage />
                        </Route>

                        <Route path="/coupon/checkout/:orderId" exact>
                          <CouponConfirmedPage />
                        </Route>

                        <Route path="/register" exact>
                          <RegisterPage />
                        </Route>

                        <Route path="/terms" exact>
                          <RegisterTermsPage />
                        </Route>

                        <Route path="/forgot-password" exact>
                          <PasswordResetPage />
                        </Route>

                        <Route path="/change-password" exact>
                          <PasswordResetFormPage />
                        </Route>

                        <Route path="/register/info" exact>
                          <FinishRegistrationPage />
                        </Route>

                        <Route path="/confirmIdentity" exact>
                          <IdentificationConfirmPage />
                        </Route>

                        <Route path="/transfer-request" exact>
                          <TransferRequestPage />
                        </Route>
                        <Route path="/transfer-request/preview" exact>
                          <TransferPreviewPage />
                        </Route>
                        <Route path="/transfer-request/success" exact>
                          <TransferSuccessPage />
                        </Route>
                        <Redirect to="/"></Redirect>
                      </Switch>
                    </IdleTimerContainer>

                    <PaymentRedirectModal />

                    <Footer show={showHeaders} />
                  </GoogleAnalyticsTag>
                </GoogleAnalytics>
              </Router>

              <ErrorModal showError={showError} />
              <CookiesConsent />
            </TransferProvider>
          </CartProvider>
        </SettingsProvider>
      </UserProvider>
    </FetchProvider>
  );
}

export default App;
