import React, { useEffect } from 'react';
import SessionStorage from '../../constants/sessionStorage';

const UserStateContext = React.createContext();
const UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case 'RESET': {
      return {
        ...SessionStorage.initialState.user,
      };
    }
    case 'LOGOUT': {
      SessionStorage.setContent({
        sessionId: null,
        token: null,
        refreshToken: null,
        user: SessionStorage.initialState.user,
      });
      return {
        ...SessionStorage.initialState.user,
      };
    }
    case 'UPDATE': {
      return { ...state, ...action.data };
    }
    case 'UPDATE_TOKENS': {
      SessionStorage.setContent({
        token: action.data.token,
        refreshToken: action.data.refreshToken,
      });
      return { ...state };
    }
    case 'UPDATE_COUPON_SUM': {
      SessionStorage.setContent({
        couponSum: action.data.couponSum,
      });
      return { ...state };
    }
    case 'UPDATE_EMAIL_TOKEN': {
      SessionStorage.setContent({
        emailToken: action.data.emailToken,
      });
      return { ...state };
    }
    case 'UPDATE_EMAIL_TOKEN_CONFIRMED': {
      SessionStorage.setContent({
        emailTokenConfirmed: action.data.emailTokenConfirmed,
      });
      return { ...state };
    }
    case 'SET_SESSION_ID': {
      SessionStorage.setContent({
        sessionId: action.data.sessionId,
      });
      return { ...state };
    }
    case 'SET_TIMESLOT': {
      SessionStorage.setContent({
        timeSlot: action.timeSlotId,
      });
      return { ...state };
    }
    case 'SET_LEFT_CART_PAGE': {
      SessionStorage.setContent({
        left: action.left,
      });
      return { ...state };
    }

    case 'SET_CHILD': {
      return { ...state, ...action.data };
    }

    case 'REMOVE_CHILD': {
      SessionStorage.setContent({
        childEspbiEsi: null,
      });
      return { ...state };
    }

    case 'REMOVE_TIMESLOT': {
      SessionStorage.setContent({
        timeSlot: undefined,
      });
      return { ...state };
    }

    case 'SET_TIMESLOT_SERVICE_URL': {
      SessionStorage.setContent({
        redirectUrl: action.data.backUrl,
      });
      return { ...state };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider(props) {
  const [state, dispatch] = React.useReducer(
    userReducer,
    SessionStorage.getContent().user,
  );

  useEffect(() => {
    let content = SessionStorage.getContent();
    SessionStorage.setContent({
      ...content,
      ...{ user: state },
    });
  }, [state]);

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {props.children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  const context = React.useContext(UserStateContext);

  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider');
  }

  return context;
}

function useUserDispatch() {
  const context = React.useContext(UserDispatchContext);

  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }

  return context;
}

export { UserProvider, useUserState, useUserDispatch };
