import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useCartDispatch, useCartState } from '../../context/CartContext';
import queryString from 'query-string';
import { omitBy, isEmpty } from 'lodash';
import useFetch from 'use-http';
import Container from '../../shared/Container';
import Loader from '../../shared/Loader';
import Card from '../../shared/Card';
import Page from '../../shared/Page';
import Filter from './Filter';
import Styled from './Services.styles';
import NoResults from '../../shared/NoResults/NoResults';
import _ from 'lodash';
import Modal from '../../shared/Modal';
import Button from '../../shared/Button';

import WorkplaceServices from '../../../__mock__/WorkplaceServices';
import InlineLoader from '../../shared/InlineLoader/InlineLoader';
import { exponeaCapture } from '../../hooks/exponeaCapture';
import { useUserDispatch, useUserState } from '../../context/UserContext';
import { el } from 'date-fns/locale';
const USE_MOCKS = false;

const ServicesPage = (props) => {
  const HierarchyLevel = 2; // Make this generic from frontend side if other services needs to be grouped by different levels.
  const { id, categoryId } = useParams();
  const userDispatch = useUserDispatch();
  const { search: urlParams, state } = useLocation();
  const options = queryString.parse(urlParams);

  const [cityId, setCityId] = useState(null);
  const dispatch = useCartDispatch();
  const [orderedServiceGroups, setOrderedServiceGroups] = useState();
  const [filteredBySpecialty, setFilteredBySpecialty] = useState(false);
  const [selectedDepartmentFilter, setSelectedDepartmentFilter] = useState();
  const [
    laboratoryMultipleServicePayload,
    setLaboratoryMultipleServicePayload,
  ] = useState();
  const [laboratoryBackData, setLaboratoryBackData] = useState();
  const [isLaboratoryMultipleServices, setIsLaboratoryMultipleServices] =
    useState(false);

  const history = useHistory();
  const {
    loading: loadingFilters,
    error,
    data: filters = [],
  } = useFetch(
    `/Services/SearchCriterias?categoryId=${categoryId}&serviceTypeId=${id}&workplaceId=${options.workplaceId}` +
    (history.location?.state?.isPaidLaboratoryService === false
      ? '&isPsdfOnly=true'
      : ''),
    [],
  );

  const externalFamilyDoctorOtherCardInfoId =
    process.env.REACT_APP_FAMILYDOCTOREXTERNALID;

  const externalServiceTypeIdForGroupedService =
    process.env.REACT_APP_LABEXTERNALID;

  const secondaryExternalServiceTypeIdForGroupedService =
    process.env.REACT_APP_KTHERAPYEXTERNALID;

  const bySecondHierarchyLevelServiceTypeId =
    process.env.REACT_APP_VACCINEEXTERNALID;

  const esteticLaserServicesExternalServiceTypeId =
    process.env.REACT_APP_ESTETIC_LASER_SERVICE_TYPE_ID;

  const holterExternalServiceTypeId = process.env.REACT_APP_HOLTER;

  const gskServiceTypeId = process.env.REACT_APP_GSK_SERVICE_TYPE_ID;

  const vgskServiceTypeId = process.env.REACT_APP_VGSK_SERVICE_TYPE_ID;

  const kMRServiceTypeExternalId =
    process.env
      .REACT_APP_KINESITHERAPY_MASSAGES_REFLEXOTHERAPY_SERVICE_TYPE_EXTERNAL_ID;

  const kinesitherapyServiceGroupExternalId =
    process.env.REACT_APP_KINESITHERAPY_SERVICE_GROUP_EXTERNAL_ID;

  const [initialFilters, setInitialFilters] = useState(filters);

  const { get: getDepartments } = useFetch(
    `/Services/Departments?categoryId=${categoryId}&serviceTypeId=${id}&cityId=${cityId}`,
  );
  const { get, loading: loadingServices } = useFetch(
    '/Services/WorkplaceServices',
  );

  const { request: serviceRequest, response: serviceGetResponse } =
    useFetch('/Services');

  const { get: getServiceGroups, loading: getServiceGroupsLoading } = useFetch(
    '/Services/ServiceGroups',
  );

  const { get: getServiceHierarchies, loading: getServiceHierarchiesLoading } =
    useFetch('/Services/ServiceHierarchies');

  const {
    get: getServiceTypeExternalId,
    loading: getServiceTypeExternalIdLoading,
  } = useFetch('/Services/ServiceTypeExternalId');

  const {
    post: cancelRegistrationRequestSend,
    response: cancelRegistrationResponse,
  } = useFetch(`/RegistrationManagement/Registrations/Cancel`);

  const [data, setData] = useState({});
  const [arrangement, setArrangement] = useState('Default');
  const [loading, setLoading] = useState(true);
  const cartState = useCartState();
  const isLoading = [loadingFilters, loading].find((l) => l);
  const [initialLoad, setInitialLoad] = useState(false);
  const [fetchRemaining, setFetchRemaining] = useState(false);
  const [moreLoading, setMoreLoading] = useState(false);
  const [filtersAdded, setFiltersAdded] = useState(false);
  const [isActive, setIsActive] = useState();
  const [serviceGroups, setServiceGroups] = useState();
  const [externalServiceTypeId, setExternalServiceTypeId] = useState();
  const [selectedServiceGroup, setSelectedServiceGroup] = useState();
  const [serviceGroupedServices, setServiceGroupedServices] = useState();
  const [loadingGroup, setLoadingGroup] = useState(false);
  const [expandSingleGroup, setExpandSingleGroup] = useState();
  const [resetGroups, setResetGroups] = useState(false);
  const [serviceTitle, setServiceTitle] = useState('');
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [isCancelRegistration, setIsCancelRegistration] = useState(false);
  const [serviceGroupsLoading, setServiceGroupsLoading] = useState(true);
  const [psdfFilter, setPsdfFilter] = useState(false);
  const [isPaidLaboratoryService, setIsPaidLaboratoryService] = useState(false);
  const location = useLocation();
  const [isCancelModalClicked, setIsCancelModalClicked] = useState(false);

  useEffect(() => {
    if (serviceTitle) {
      exponeaCapture(
        [
          { name: 'category_1', value: serviceTitle },
          { name: 'category_id', value: categoryId },
        ],
        'view_item',
      );
    }
  }, [serviceTitle]);

  function changeActiveState(idx) {
    setIsActive((state) => [...state]);
  }
  function isGroupedServiceType(id) {
    if (
      id != secondaryExternalServiceTypeIdForGroupedService &&
      id != externalServiceTypeIdForGroupedService &&
      id != bySecondHierarchyLevelServiceTypeId &&
      id != gskServiceTypeId &&
      id != vgskServiceTypeId &&
      id != esteticLaserServicesExternalServiceTypeId
    ) {
      return false;
    }
    return true;
  }

  function GroupByHierarchies(id) {
    if (id != bySecondHierarchyLevelServiceTypeId && id != gskServiceTypeId) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    if (history.location?.state?.laboratoryMultipleServices?.length > 0) {
      setIsLaboratoryMultipleServices(true);
      setLaboratoryMultipleServicePayload(history.location?.state);
      setIsPaidLaboratoryService(
        history.location?.state?.isPaidLaboratoryService,
      );
      setLaboratoryBackData({
        lastStep: 1,
        registrationServices: [history.location?.state.values],
        resetStorage: false,
        laboratoryMultipleServices:
          history.location?.state.laboratoryMultipleServices,
      });
    }
  }, [history]);

  useEffect(() => {
    if (cartState.leftToPaysera) {
      setIsCancelModalVisible(true);
    }
  }, [cartState.leftToPaysera]);

  useEffect(async () => {
    if (isCancelRegistration) {
      await cancelRegistrationRequestSend('', {
        cancelationToken: cartState.registrationId,
      });
      dispatch({
        type: 'RESET_ONLY_REGISTRATION_ID',
      });
      history.push('/payment/error');
    }
  }, [isCancelRegistration]);

  function getServicesByGroup(serviceGroupId) {
    if (!GroupByHierarchies(externalServiceTypeId)) {
      if (serviceGroupedServices) {
        return serviceGroupedServices?.filter(
          (x) => x.serviceGroupId === JSON.parse(serviceGroupId),
        );
      } else return [];
    } else {
      if (serviceGroupedServices) {
        return serviceGroupedServices?.filter(
          (x) => x.secondLevelHierarchyId === JSON.parse(serviceGroupId),
        );
      } else return [];
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (USE_MOCKS) {
      setData(WorkplaceServices);
    } else {
      const serviceType = await getServiceTypeExternalId(
        `?serviceTypeId=${id}`,
      );

      setExternalServiceTypeId(serviceType?.id);
      setServiceTitle(serviceType.title);
      if (!isGroupedServiceType(serviceType?.id)) {
        setLoading(true);
        const queryParams = queryString.stringify({
          ...queryString.parse(history.location.search),
        });
        get(
          '?' +
          queryString.stringify({
            ...queryString.parse(history.location.search),
            serviceTypeId: id,
          }) +
          `&skip=${0}&take=${queryParams ? 999999 : 20}&workplaceId=${
            options.workplaceId
          }`,
        ).then((data) => {
          setData(data);
          setLoading(false);
          setFetchRemaining(queryParams ? false : true);
        });
      } else if (
        isGroupedServiceType(serviceType?.id) &&
        history.location.search !== '?' &&
        history.location.search !== ''
      ) {
        setLoadingGroup(true);
        setLoading(true);
        get(
          history.location.search !== '?' && history.location.search !== ''
            ? '?' +
            queryString.stringify({
              ...queryString.parse(history.location.search),
              serviceTypeId: id,
            }) +
            `&skip=${0}&take=${9999999}&groupByServiceGroup=${!GroupByHierarchies(
              serviceType.id,
            )}&level=${HierarchyLevel}`
            : `&skip=0&take=99999999&serviceGroupId=${selectedServiceGroup}&groupByServiceGroup=${!GroupByHierarchies(
              serviceType.id,
            )}`,
        ).then((data) => {
          setServiceGroupedServices(data.services);
          setLoading(false);

          if (
            history.location.search !== '?' &&
            history.location.search !== ''
          ) {
            setExpandSingleGroup(data);
          }
          setLoadingGroup(false);
          setFetchRemaining(false);
        });
      } else if (
        history.location.search === '?' ||
        history.location.search === ''
      ) {
        setExpandSingleGroup(null);
        setResetGroups(true);
      }
    }
  }, [
    externalServiceTypeIdForGroupedService,
    get,
    getServiceTypeExternalId,
    history.location.search,
    id,
    secondaryExternalServiceTypeIdForGroupedService,
    esteticLaserServicesExternalServiceTypeId,
  ]);

  useEffect(() => {
    if (
      externalServiceTypeId != undefined &&
      externalServiceTypeId != bySecondHierarchyLevelServiceTypeId &&
      externalServiceTypeId != gskServiceTypeId &&
      isGroupedServiceType(externalServiceTypeId)
    ) {
      setServiceGroupsLoading(true);
      // setLoading(true);
      getServiceGroups(
        `?serviceTypeId=${id}&workplaceId=${options.workplaceId}`,
      ).then((res) => {
        setServiceGroups(res);
        setLoading(false);
        setServiceGroupsLoading(false);
      });
    } else if (
      externalServiceTypeId != undefined &&
      isGroupedServiceType(externalServiceTypeId)
    ) {
      setServiceGroupsLoading(true);
      getServiceHierarchies(`?serviceTypeId=${id}&level=2`).then((res) => {
        setServiceGroups(res);
        setLoading(false);
        setServiceGroupsLoading(false);
      });
    }
  }, [
    bySecondHierarchyLevelServiceTypeId,
    externalServiceTypeId,
    getServiceGroups,
    getServiceHierarchies,
    id,
    resetGroups,
  ]);

  useEffect(() => {
    if (externalServiceTypeId == holterExternalServiceTypeId && serviceTitle) {
      history.push('/kompleksines-paslaugos?holter=true', {
        services: 'holter',
        serviceTitle: serviceTitle,
      });
    }
  }, [externalServiceTypeId, serviceTitle]);

  useEffect(() => {
    if (
      selectedServiceGroup &&
      (history.location.search === '?' || history.location.search === '')
    ) {
      setLoadingGroup(true);

      get(
        '?' +
        queryString.stringify({
          ...queryString.parse(history.location.search),
          serviceTypeId: id,
        }) +
        (GroupByHierarchies(externalServiceTypeId)
          ? `&skip=${0}&take=${9999999}&hierarchyId=${selectedServiceGroup}&level=${HierarchyLevel}`
          : `&skip=${0}&take=${9999999}&serviceGroupId=${selectedServiceGroup}`),
      ).then((data) => {
        setServiceGroupedServices(data.services);

        // setData(data);
        setLoadingGroup(false);
        setFetchRemaining(false);
      });
    }
  }, [get, id, selectedServiceGroup]);

  useEffect(() => {
    if (fetchRemaining) {
      setMoreLoading(true);
      get(
        '?' +
        queryString.stringify({
          ...queryString.parse(history.location.search),
          serviceTypeId: id,
        }) +
        `&skip=${0}&take=${999999}`,
      ).then((data) => {
        setData(data);
        setMoreLoading(false);
        setFetchRemaining(false);
      });
    }
  }, [fetchRemaining]);

  useEffect(() => {
    if (cityId) {
      getDepartments().then((data) => {
        setInitialFilters(data);
        history.replace({ search: `?cityId=` + cityId });
      });
    } else {
      getDepartments().then((data) => {
        setInitialFilters(data);
        history.replace({ search: '' });
      });
    }
  }, [cityId]);

  useEffect(() => {
    if (filters.length !== 0 && !filtersAdded) {
      if (
        queryString.parse(history.location.search)?.specialtyId !== undefined
      ) {
        setFilteredBySpecialty(true);
      }

      if (
        queryString.parse(history.location.search)?.departmentId !== undefined
      ) {
        setSelectedDepartmentFilter(
          queryString.parse(history.location.search)?.departmentId,
        );
      }
      setFiltersAdded(true);
      setInitialFilters(filters);
    }
  }, [filters]);

  function updateFilters({ value, name }) {
    if (name === 'isPsdfId') {
      setPsdfFilter(value);
    }
    if (name === 'specialtyId' && value != '') {
      setFilteredBySpecialty(true);
    } else if (name === 'specialtyId' && value == '') {
      setFilteredBySpecialty(false);
    }
    setSelectedServiceGroup(undefined);

    if (name === 'departmentId' && value) {
      setSelectedDepartmentFilter(value);
    } else if (name === 'departmentId' && !value) {
      setSelectedDepartmentFilter(undefined);
    }

    if (name === 'cityId' && value) {
      setCityId(value);
    } else if (name === 'cityId' && !value) {
      setCityId(undefined);
    }

    const q = queryString.stringify(
      omitBy({ ...options, [name]: value }, isEmpty),
    );
    history.push({ search: `?${q}` });
  }

  useEffect(() => {
    const active = [];
    let servGroups = [];
    if (serviceGroups?.length > 0) {
      if (resetGroups || !expandSingleGroup) {
        setResetGroups(false);
        if (serviceGroups?.length === 1) {
          active.push({
            externalId: serviceGroups[0].externalId,
            isActive: true,
          });
          setSelectedServiceGroup(active[0].externalId);
          servGroups = serviceGroups;
        } else {
          const ordered = _.orderBy(serviceGroups, ['name'], ['asc']);
          const idxToMoveUp = ordered.find(
            ({ name }) => name === 'Ištyrimo programos',
          );

          if (idxToMoveUp) {
            const idx = ordered.indexOf(idxToMoveUp);
            ordered.splice(idx, 1);
            ordered.unshift(idxToMoveUp);
          }

          servGroups = ordered;
          ordered.map(({ externalId, name }, i) => {
            active.push({ externalId: externalId, isActive: false });
          });
        }

        setIsActive(active);
      } else if (expandSingleGroup && !resetGroups) {
        const filteredGroups = [];

        expandSingleGroup?.serviceGroups?.forEach((s) => {
          if (s.id == null) {
            filteredGroups.push({
              hierarchiesId: null,
              name: 'Kita',
              externalId: 'null',
              level: 2,
            });
          } else {
            filteredGroups.push(
              serviceGroups?.find((x) => x?.externalId === s?.id),
            );
          }
        });
        servGroups = filteredGroups;
        if (servGroups.length === 1 || filteredBySpecialty) {
          if (filteredBySpecialty) {
            const activeArr = [];
            servGroups.map(({ externalId, name }, i) => {
              activeArr.push({ externalId: externalId, isActive: true });
            });
            setIsActive(activeArr);
          } else {
            const singleGroup = [
              {
                externalId: servGroups[0]?.externalId,
                isActive: true,
              },
            ];
            setIsActive(singleGroup);
            setSelectedServiceGroup(singleGroup[0].externalId);
            setServiceGroupedServices(expandSingleGroup?.services);
          }
        } else {
          const activeArr = [];
          servGroups.map(({ externalId, name }, i) => {
            if (externalId !== undefined) {
              activeArr.push({ externalId, isActive: false });
            }
          });
          setIsActive(activeArr);
        }
      }

      var orderedGroups = _.orderBy(servGroups, ['name'], ['asc']);
      setOrderedServiceGroups(orderedGroups);
    }
  }, [serviceGroups, expandSingleGroup, resetGroups]);

  function sortData(arrangement) {
    if (arrangement === 'Name') {
      let sortedServices = _.orderBy(
        data.services,
        ['specialistName'],
        ['asc'],
      );
      setData((prev) => ({ ...prev, services: sortedServices }));
      setArrangement('Name');
    } else if (arrangement === 'Default') {
      setArrangement('Default');
    } else if (arrangement === 'Service') {
      let sortedServices = _.orderBy(data.services, ['serviceTitle'], ['asc']);
      setData((prev) => ({ ...prev, services: sortedServices }));
      setArrangement('Service');
    } else if (arrangement === 'Nearest visit') {
      let sortedServices = _.orderBy(data.services, ['upcomingVisit'], ['asc']);
      setData((prev) => ({ ...prev, services: sortedServices }));
      setArrangement('Nearest visit');
    }
  }

  function getTitle() {
    return serviceTitle;
  }

  function clearCart() {
    dispatch({
      type: 'REMOVE LEFTTOPAYSERA',
    });
  }

  function addToMultipleLaboratoryServicesCallback(newSelectedService) {
    serviceRequest
      .get(
        `WorkplaceServices/${newSelectedService.workplaceId}/${newSelectedService.serviceId}`,
      )
      .then((response) => {
        let multipleServices =
          laboratoryMultipleServicePayload.laboratoryMultipleServices;
        response = {
          ...response,
          isPaidLaboratoryService: isPaidLaboratoryService,
        };
        if (
          !multipleServices.some(
            (service) => service.serviceId === response.serviceId,
          )
        ) {
          multipleServices = [...multipleServices, response];
        }

        history.push(laboratoryMultipleServicePayload.backUrl, {
          lastStep: 1,
          registrationServices: [laboratoryMultipleServicePayload.values],
          resetStorage: false,
          laboratoryMultipleServices: multipleServices
        });
      });
  }

  function getCombinedServicesOfSimpleAndComplex() {
    const combinedServices = [];

    if (data.complexServices && data.complexServices.length > 0) {
      combinedServices.push(
        ...data.complexServices.map((service) => ({
          source: 'complex',
          data: service,
        })),
      );
    }

    if (data?.services) {
      combinedServices.push(
        ...data.services
          .filter(
            (x) => (psdfFilter && x.nearestPsdfDate !== null) || !psdfFilter,
          )
          .map((service) => ({ source: 'default', data: service })),
      );
    }

    // Sort the combined array alphabetically by title
    const sortedServices = combinedServices.sort((a, b) => {
      let titleA, titleB;

      if (a.source === 'complex') {
        titleA = a.data.service.title;
      } else {
        titleA = a.data.serviceTitle;
      }

      if (b.source === 'complex') {
        titleB = b.data.service.title;
      } else {
        titleB = b.data.serviceTitle;
      }

      // Convert to uppercase for case-insensitive sorting
      const titleAUpper = titleA.toUpperCase();
      const titleBUpper = titleB.toUpperCase();

      return titleAUpper.localeCompare(titleBUpper);
    });
    return sortedServices;
  }

  function customOrderBy(dataToOrder, orderBy, orderDirection) {
    if (
      (!arrangement || arrangement === 'Default') &&
      dataToOrder.some(
        (x) =>
          x?.serviceTypeExternalId == kMRServiceTypeExternalId &&
          x?.serviceGroupId == kinesitherapyServiceGroupExternalId,
      )
    ) {
      const serviceTitle = 'kineziterapeuto konsultacija';
      const priorityData = dataToOrder.filter((item) =>
        item.serviceTitle.toLowerCase().includes(serviceTitle),
      );

      const otherData = dataToOrder.filter(
        (item) => !item.serviceTitle.toLowerCase().includes(serviceTitle),
      );

      const orderedPriorityData = _.orderBy(
        priorityData,
        ['serviceTitle', 'upcomingVisit', 'cardTitle'],
        ['asc'],
      );

      const orderedOtherData = _.orderBy(
        otherData,
        ['serviceTitle', 'upcomingVisit', 'cardTitle'],
        ['asc'],
      );

      return orderedPriorityData.concat(orderedOtherData);
    }

    return _.orderBy(dataToOrder, orderBy, orderDirection);
  }

  function adjustOrderWithSorting(orderSequence) {
    switch (arrangement) {
      case 'Name':
        return ['specialistName'];
      case 'Service':
        return ['serviceTitle'];
      case 'Nearest visit':
        return ['upcomingVisit'];
      default:
        return orderSequence;
    }
  }

  return (
    <Page
      error={(!data && !serviceGroups && !loadingFilters) || error}
      loading={isLoading}
    >
      <Container marginTop={true}>
        {isLaboratoryMultipleServices && (
          <Page.Title
            backUrl={laboratoryMultipleServicePayload.backUrl}
            backData={laboratoryBackData}
            greyTheme={true}
            smallHeight={true}
          >
            {' '}
            {getTitle()}
          </Page.Title>
        )}
        {!isLaboratoryMultipleServices && (
          <Page.Title backUrl={`/`} greyTheme={true} smallHeight={true}>
            {' '}
            {getTitle()}
          </Page.Title>
        )}
      </Container>
      <Filter
        value={options}
        onChange={updateFilters}
        isLoading={loadingFilters}
        filters={initialFilters}
        onClear={() => {
          setCityId(null);
          getDepartments().then((data) => {
            setInitialFilters(data);
          });
          history.push({
            search:
              isLaboratoryMultipleServices && options.workplaceId
                ? `?workplaceId=${options.workplaceId}` +
                (isPaidLaboratoryService === false ? '&isPsdfId=true' : '')
                : '',
          });
        }}
        category={categoryId}
        sortData={sortData}
        isGrid={categoryId === '1'}
        enableSorting={true}
        disabledFiltersById={
          isLaboratoryMultipleServices && isPaidLaboratoryService === false
            ? ['isPsdfId']
            : []
        }
      />

      <Container>
        {(!isGroupedServiceType(externalServiceTypeId) && loading) ||
          (isGroupedServiceType(externalServiceTypeId) && loading) ? (
          <Styled.ServicesLoader>
            <Loader />
          </Styled.ServicesLoader>
        ) : (
          <>
            <>
              {orderedServiceGroups !== undefined &&
                orderedServiceGroups?.length > 0 ? (
                isGroupedServiceType(externalServiceTypeId) &&
                orderedServiceGroups?.length === isActive.length &&
                orderedServiceGroups?.map(({ externalId, name }, idx) => {
                  return (
                    <>
                      <Styled.PricesAccordionTitle
                        key={idx}
                        topMargin={true}
                        onClick={() => {
                          if (!loadingGroup) {
                            isActive.find(
                              (r) => r.externalId == externalId,
                            ).isActive = !isActive.find(
                              (r) => r.externalId == externalId,
                            ).isActive;

                            isActive.forEach((s) => {
                              if (s.externalId != externalId) {
                                s.isActive = false;
                              }
                            });

                            if (
                              isActive.find((r) => r.externalId == externalId)
                                .isActive
                            ) {
                              if (externalId != selectedServiceGroup) {
                                // setServiceGroupedServices([]);
                              }
                              setSelectedServiceGroup(externalId);
                            }
                            changeActiveState(externalId);
                          }
                        }}
                      >
                        <div>{name}</div>

                        <div>
                          {isActive.find((r) => r.externalId == externalId)
                            .isActive
                            ? '-'
                            : '+'}
                        </div>
                      </Styled.PricesAccordionTitle>

                      {isActive.find((r) => r.externalId == externalId)
                        .isActive && (
                          <Styled.PricesAccordionContent key={idx + 1}>
                            {loadingGroup && (
                              <InlineLoader light={true}></InlineLoader>
                            )}
                            <Card.List noTopMargin={true}>
                              {externalServiceTypeId == gskServiceTypeId &&
                                customOrderBy(
                                  getServicesByGroup(externalId)?.filter(
                                    (x) =>
                                      x.upcomingVisit !== null &&
                                      ((psdfFilter &&
                                        x.nearestPsdfDate !== null) ||
                                        !psdfFilter),
                                  ),
                                  adjustOrderWithSorting([
                                    'upcomingVisit',
                                    'serviceTitle',
                                    'cardTitle',
                                  ]),
                                  ['asc'],
                                ).map((service, i) => {
                                  return (
                                    <Card
                                      key={i}
                                      service={service}
                                      categoryId={categoryId}
                                      serviceTypeId={id}
                                      backUrl={urlParams}
                                      noRegistrationService={false}
                                      isDisabled={laboratoryMultipleServicePayload?.laboratoryMultipleServices.some(
                                        (labService) =>
                                          labService.serviceId ==
                                          service.serviceId,
                                      )}
                                      addToMultipleServicesCallback={
                                        !!isLaboratoryMultipleServices
                                          ? () =>
                                            addToMultipleLaboratoryServicesCallback(
                                              service,
                                            )
                                          : undefined
                                      }
                                    />
                                  );
                                })}

                              {externalServiceTypeId != gskServiceTypeId &&
                                customOrderBy(
                                  getServicesByGroup(externalId)?.filter(
                                    (x) =>
                                      x.upcomingVisit !== null &&
                                      ((psdfFilter &&
                                        x.nearestPsdfDate !== null) ||
                                        !psdfFilter),
                                  ),
                                  adjustOrderWithSorting([
                                    'serviceTitle',
                                    'cardTitle',
                                    'upcomingVisit',
                                  ]),
                                  ['asc'],
                                ).map((service, i) => {
                                  return (
                                    <Card
                                      key={i}
                                      service={service}
                                      categoryId={categoryId}
                                      serviceTypeId={id}
                                      backUrl={urlParams}
                                      noRegistrationService={false}
                                      isDisabled={laboratoryMultipleServicePayload?.laboratoryMultipleServices.some(
                                        (labService) =>
                                          labService.serviceId ==
                                          service.serviceId,
                                      )}
                                      addToMultipleServicesCallback={
                                        !!isLaboratoryMultipleServices
                                          ? () =>
                                            addToMultipleLaboratoryServicesCallback(
                                              service,
                                            )
                                          : undefined
                                      }
                                    />
                                  );
                                })}

                              {externalServiceTypeId == gskServiceTypeId &&
                                customOrderBy(
                                  getServicesByGroup(externalId)?.filter(
                                    (x) =>
                                      x.upcomingVisit === null &&
                                      ((psdfFilter &&
                                        x.nearestPsdfDate !== null) ||
                                        !psdfFilter),
                                  ),
                                  adjustOrderWithSorting([
                                    'upcomingVisit',
                                    'serviceTitle',
                                    'cardTitle',
                                  ]),
                                  ['asc'],
                                ).map((service, i) => {
                                  return (
                                    <Card
                                      key={i}
                                      service={service}
                                      categoryId={categoryId}
                                      serviceTypeId={id}
                                      backUrl={urlParams}
                                      noRegistrationService={false}
                                      isDisabled={laboratoryMultipleServicePayload?.laboratoryMultipleServices.some(
                                        (labService) =>
                                          labService.serviceId ==
                                          service.serviceId,
                                      )}
                                      addToMultipleServicesCallback={
                                        !!isLaboratoryMultipleServices
                                          ? () =>
                                            addToMultipleLaboratoryServicesCallback(
                                              service,
                                            )
                                          : undefined
                                      }
                                    />
                                  );
                                })}

                              {externalServiceTypeId != gskServiceTypeId &&
                                customOrderBy(
                                  getServicesByGroup(externalId)?.filter(
                                    (x) =>
                                      x.upcomingVisit === null &&
                                      ((psdfFilter &&
                                        x.nearestPsdfDate !== null) ||
                                        !psdfFilter),
                                  ),
                                  adjustOrderWithSorting([
                                    'serviceTitle',
                                    'cardTitle',
                                    'upcomingVisit',
                                  ]),
                                  ['asc'],
                                ).map((service, i) => {
                                  return (
                                    <Card
                                      key={i}
                                      service={service}
                                      categoryId={categoryId}
                                      serviceTypeId={id}
                                      backUrl={urlParams}
                                      noRegistrationService={false}
                                      isDisabled={laboratoryMultipleServicePayload?.laboratoryMultipleServices.some(
                                        (labService) =>
                                          labService.serviceId ==
                                          service.serviceId,
                                      )}
                                      addToMultipleServicesCallback={
                                        !!isLaboratoryMultipleServices
                                          ? () =>
                                            addToMultipleLaboratoryServicesCallback(
                                              service,
                                            )
                                          : undefined
                                      }
                                    />
                                  );
                                })}
                            </Card.List>
                          </Styled.PricesAccordionContent>
                        )}
                    </>
                  );
                })
              ) : (
                <></>
                // <NoResults>Paieškos rezultatų nėra</NoResults>
              )}

              {!isGroupedServiceType(externalServiceTypeId) &&
                (data?.services?.length ? (
                  <Card.List>
                    {arrangement !== 'Service' &&
                      data.complexServices &&
                      data.complexServices.length > 0 &&
                      _.orderBy(data.complexServices).map((service, i) => {
                        return (
                          <Card
                            isComplex={true}
                            key={i}
                            service={service.service}
                            categoryId={3}
                            backUrl={urlParams}
                            noRegistrationService={false}
                            complexPrices={service.prices}
                            selectedFilterDepartment={selectedDepartmentFilter}
                            isDisabled={laboratoryMultipleServicePayload?.laboratoryMultipleServices.some(
                              (labService) =>
                                labService.serviceId == service.serviceId,
                            )}
                            addToMultipleServicesCallback={
                              !!isLaboratoryMultipleServices
                                ? () =>
                                  addToMultipleLaboratoryServicesCallback(
                                    service,
                                  )
                                : undefined
                            }
                          />
                        );
                      })}

                    {arrangement === 'Default' &&
                      _.orderBy(
                        data?.services?.filter(
                          (x) =>
                            x.upcomingVisit !== null &&
                            ((psdfFilter && x.nearestPsdfDate !== null) ||
                              !psdfFilter),
                        ),
                        ['serviceTitle', 'cardTitle', 'upcomingVisit'],
                        ['asc'],
                      ).map((service, i) => {
                        return (
                          <Card
                            key={i}
                            service={service}
                            categoryId={categoryId}
                            serviceTypeId={id}
                            backUrl={urlParams}
                            noRegistrationService={
                              externalFamilyDoctorOtherCardInfoId ==
                              externalServiceTypeId
                            }
                            isDisabled={laboratoryMultipleServicePayload?.laboratoryMultipleServices.some(
                              (labService) =>
                                labService.serviceId == service.serviceId,
                            )}
                            addToMultipleServicesCallback={
                              !!isLaboratoryMultipleServices
                                ? () =>
                                  addToMultipleLaboratoryServicesCallback(
                                    service,
                                  )
                                : undefined
                            }
                          />
                        );
                      })}
                    {arrangement === 'Default' &&
                      _.orderBy(
                        data?.services?.filter(
                          (x) =>
                            x.upcomingVisit === null &&
                            ((psdfFilter && x.nearestPsdfDate !== null) ||
                              !psdfFilter),
                        ),
                        ['serviceTitle', 'cardTitle', 'upcomingVisit'],
                        ['asc'],
                      ).map((service, i) => {
                        return (
                          <Card
                            key={i}
                            service={service}
                            categoryId={categoryId}
                            serviceTypeId={id}
                            backUrl={urlParams}
                            noRegistrationService={
                              externalFamilyDoctorOtherCardInfoId ==
                              externalServiceTypeId
                            }
                            isDisabled={laboratoryMultipleServicePayload?.laboratoryMultipleServices.some(
                              (labService) =>
                                labService.serviceId == service.serviceId,
                            )}
                            addToMultipleServicesCallback={
                              !!isLaboratoryMultipleServices
                                ? () =>
                                  addToMultipleLaboratoryServicesCallback(
                                    service,
                                  )
                                : undefined
                            }
                          />
                        );
                      })}

                    {arrangement !== 'Default' &&
                      arrangement !== 'Service' &&
                      data?.services
                        ?.filter(
                          (x) =>
                            (psdfFilter && x.nearestPsdfDate !== null) ||
                            !psdfFilter,
                        )
                        .map((service, i) => {
                          return (
                            <Card
                              key={i}
                              service={service}
                              categoryId={categoryId}
                              serviceTypeId={id}
                              backUrl={urlParams}
                              noRegistrationService={
                                externalFamilyDoctorOtherCardInfoId ==
                                externalServiceTypeId
                              }
                              isDisabled={laboratoryMultipleServicePayload?.laboratoryMultipleServices.some(
                                (labService) =>
                                  labService.serviceId == service.serviceId,
                              )}
                              addToMultipleServicesCallback={
                                !!isLaboratoryMultipleServices
                                  ? () =>
                                    addToMultipleLaboratoryServicesCallback(
                                      service,
                                    )
                                  : undefined
                              }
                            />
                          );
                        })}

                    {arrangement === 'Service' &&
                      getCombinedServicesOfSimpleAndComplex().map((item, i) => {
                        const { source, data } = item;
                        return (
                          <Card
                            key={i}
                            service={source === 'complex' ? data.service : data} // Depending on the source
                            categoryId={source === 'complex' ? 3 : categoryId} // Depending on the source
                            serviceTypeId={
                              source === 'complex' ? undefined : id
                            } // Depending on the source
                            backUrl={urlParams}
                            noRegistrationService={
                              source === 'complex'
                                ? false
                                : externalFamilyDoctorOtherCardInfoId ===
                                externalServiceTypeId
                            } // Depending on the source
                            complexPrices={
                              source === 'complex' ? data.prices : undefined
                            } // Depending on the source
                            selectedFilterDepartment={selectedDepartmentFilter}
                            isComplex={source === 'complex'} // Depending on the source
                          />
                        );
                      })}
                  </Card.List>
                ) : (
                  <div>
                    {!loading && (
                      <NoResults>Paieškos rezultatų nėra </NoResults>
                    )}
                  </div>
                ))}

              {moreLoading && (
                <Styled.ServicesLoader>
                  <Loader />
                </Styled.ServicesLoader>
              )}
            </>
          </>
        )}
      </Container>

      <Modal
        visible={cartState.leftToPaysera}
        showClose={false}
        onClose={() => {
          setIsCancelModalVisible(false);
          dispatch({
            type: 'REMOVE_LEFTTOPAYSERA',
          });
          dispatch({
            type: 'RESET_PAYSERA_URL',
          });

          userDispatch({
            type: 'SET_LEFT_CART_PAGE',
            left: false,
          });
        }}
      >
        <Modal.TextContent>
          Ar tikrai norite atšaukti registraciją?
        </Modal.TextContent>
        <Modal.Actions>
          <Button
            size="large"
            onClick={() => {
              dispatch({ type: 'SET_LEFTTOPAYSERA' });
              setIsCancelRegistration(false);
              setIsCancelModalVisible(false);
              window.location.replace(cartState.payseraUrl);
            }}
          >
            Grįžti
          </Button>
          <Button
            size="large"
            onClick={() => {
              setIsCancelRegistration(true);
              setIsCancelModalVisible(false);
              dispatch({
                type: 'REMOVE_LEFTTOPAYSERA',
              });
              dispatch({
                type: 'RESET_CART',
              });

              dispatch({
                type: 'RESET_PAYSERA_URL',
              });

              userDispatch({
                type: 'SET_LEFT_CART_PAGE',
                left: false,
              });
            }}
          >
            Taip
          </Button>
        </Modal.Actions>
      </Modal>
    </Page>
  );
};

export default ServicesPage;
