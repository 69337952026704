import { Field, Form as FormikForm, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useFetch from 'use-http';
import * as Yup from 'yup';
import SessionStorage from '../../../constants/sessionStorage';
import { useUserDispatch, useUserState } from '../../../context/UserContext';
import Button from '../../../shared/Button';
import Container from '../../../shared/Container';
import ErrorModal from '../../../shared/ErrorModal';
import Form from '../../../shared/Form';
import Page from '../../../shared/Page';
import TextField from '../../../shared/TextField';
import Styled from './../LoginOptions/LoginOptions.styles';
import useRedirection from '../../../hooks/useRedirection';
const SmsCodeConfirmation = () => {
  const userState = useUserState();
  const sessionDispatch = useUserDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [clearCartState] = useRedirection();
  const [PhoneNumberToConfirm, setPhoneNumberToConfirm] = useState(
    history.location.state?.phoneNumber,
  );
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [backUrl] = useState(() => {
    switch (history.location.state?.from) {
      case 'edit':
        return '/account';
      case 'passwordReset':
        return '/forgot-password';
      case 'login':
        return '/selfservice';
      default:
        return '/register/info';
    }
  });
  const initialValues = {
    smsCode: '',
  };

  useEffect(() => {
    return () => {
      clearCartState();
    };
  }, [clearCartState]);

  const validationSchema = Yup.object().shape({
    smsCode: Yup.number()
      .required(t('validation.requiredField'))
      .typeError(t('validation.smsFormat'))
      .test('len', t('validation.smsCodeLength'), (val) => {
        if (val) return val.toString().length === 4;
      }),
  });

  const { post: formPost, response: formResponse } = useFetch(
    `/UserAccount/ConfirmPhoneNumber`,
  );

  const {
    post: confirmAndChangePhoneNumber,
    response: confirmAndChangePhoneNumberResponse,
  } = useFetch(`/UserAccount/confirmAndChangePhoneNumber`);

  const { post: loginStep2Post, response: loginStep2Response } = useFetch(
    `/UserAccount/Login/Step2`,
  );

  const handleSubmit = async (data, { setSubmitting, setErrors }) => {
    if (history.location.state?.from === 'login')
      await handleLoginStep2(data, { setSubmitting, setErrors });
    else if (history.location.state?.from === 'edit')
      await handleSmsCodeConfirmationAndPhoneNumberChange(data, {
        setSubmitting,
        setErrors,
      });
    else await handleSmsCodeConfirmation(data, { setSubmitting, setErrors });
  };

  const handleLoginStep2 = async (data, { setSubmitting, setErrors }) => {
    let content = SessionStorage.getContent();

    setSubmitting(true);
    await loginStep2Post('', {
      sessionId: content.sessionId,
      code: data.smsCode,
    });
    setSubmitting(false);
    const response = await loginStep2Response.json();
    if (loginStep2Response.status >= 500) {
      setErrorModalVisible(true);
    } else if (loginStep2Response.status >= 400) {
      const errors = {
        smsCode: response.exceptionDetails[0]?.message,
      };
      setErrors(errors);
    } else {
      sessionDispatch({
        type: 'UPDATE_TOKENS',
        data: {
          sessionId: null,
          token: response.token,
          refreshToken: response.refreshToken,
        },
      });
      sessionDispatch({
        type: 'UPDATE',
        data: {
          ...response.user,
          phoneNumberConfirmed: true,
          isLoggedIn: true,
        },
      });
      history.push('/account');
    }
  };

  const handleSmsCodeConfirmation = async (
    data,
    { setSubmitting, setErrors },
  ) => {
    setSubmitting(true);
    await formPost('', {
      userId: userState.id,
      code: data.smsCode,
    });
    setSubmitting(false);
    const response = await formResponse.json();
    if (formResponse.status >= 500) {
      setErrorModalVisible(true);
    } else if (formResponse.status >= 400) {
      const errors = {
        smsCode: response.exceptionDetails[0]?.message,
      };
      setErrors(errors);
    } else {
      if (history.location.state?.from === 'passwordReset') {
        history.push('/change-password');
      } else {
        sessionDispatch({
          type: 'UPDATE',
          data: { phoneNumberConfirmed: true, isLoggedIn: true },
        });
        history.push('/account');
      }
    }
  };

  const handleSmsCodeConfirmationAndPhoneNumberChange = async (
    data,
    { setSubmitting, setErrors },
  ) => {
    setSubmitting(true);
    await confirmAndChangePhoneNumber('', {
      phoneNumber: PhoneNumberToConfirm,
      code: data.smsCode,
    });
    setSubmitting(false);
    const response = await confirmAndChangePhoneNumberResponse.json();
    if (confirmAndChangePhoneNumberResponse.status >= 500) {
      setErrorModalVisible(true);
    } else if (confirmAndChangePhoneNumberResponse.status >= 400) {
      const errors = {
        smsCode: response.exceptionDetails[0]?.message,
      };
      setErrors(errors);
    } else {
      if (history.location.state?.from === 'passwordReset') {
        history.push('/change-password');
      } else {
        sessionDispatch({
          type: 'UPDATE',
          data: {
            phoneNumberConfirmed: true,
            isLoggedIn: true,
            phoneNumber: PhoneNumberToConfirm,
          },
        });
        history.push('/account');
      }
    }
  };

  return (
    <Page loading={false}>
      <Container>
        <Styled.Form>
          <Page.Title backUrl={backUrl} greyTheme={true}>
            {t('login.credentialsTab.confirmation.title')}
          </Page.Title>

          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, errors, isSubmitting, values }) => (
              <FormikForm>
                <Form>
                  <Form.Group>
                    <Styled.SmartIdTitle greyTheme={true}>
                      {t('login.credentialsTab.confirmation.tabName')}
                    </Styled.SmartIdTitle>
                    <Form.Row>
                      <Field
                        focus={true}
                        name="smsCode"
                        autoComplete="off"
                        placeholder={t(
                          'login.credentialsTab.confirmation.label',
                        )}
                        component={TextField}
                        isGrey={true}
                      />
                    </Form.Row>
                  </Form.Group>
                </Form>
                <div className="mt-4 mt-md-2 d-flex align-center justify-center">
                  <Button
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    style={{ width: '50%' }}
                    size="mega"
                    type="submit"
                    onClick={() => handleSubmit}
                  >
                    {t('login.credentialsTab.confirmation.continue')}
                  </Button>
                </div>
              </FormikForm>
            )}
          </Formik>
        </Styled.Form>
      </Container>
      <ErrorModal
        onClose={() => {
          setErrorModalVisible(false);
        }}
        showError={errorModalVisible}
      />
    </Page>
  );
};

export default SmsCodeConfirmation;
