import styled, { css } from 'styled-components/macro';
import { mediaDown } from '../../../../utils/styles/media';

const CouponCheckbox = styled.div`
  line-height: 28px;
  text-align: right;
  ${mediaDown('md')`    
    line-height: 19px;
    text-align: left;
  `}
`;

const CouponFieldsParent = styled.div`
  display: flex;
  justify-content: right;
  flex-direction: row;
  margin-bottom: 10px;
`;

const CouponFields = styled.div`
  width: calc(100% - 485px);
  display: ${(props) => (props.active ? 'block' : 'none')};
  ${mediaDown('md')`
  width: 100%;
`};
`;

const CouponFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 10px;
`;

const CouponButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100px;
  align-self: start;
  padding-top: 15px;
  ${mediaDown('md')`
    height:45px;
    align-self: start;
    padding-top: 45px;
  `};
`;

const CouponParagraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60px;
  align-self: start;
  ${mediaDown('md')`
    height:45px;
  `};
`;

const MainContainerDiv = styled.div`
  margin-bottom: ${(props) => (props.message ? '0px' : '30px')};
`;

const FormButtons = styled.div`
  display: flex;
  ${mediaDown('md')`
    display: grid;
    flex: auto;
  button:not(:first-child) {
    margin-top: 20px;
  }
  `};
`;

const Trans = styled.div`
  font-size: 22px;
`;

const Styled = {
  CouponCheckbox,
  CouponFields,
  CouponFieldsParent,
  CouponFieldsContainer,
  CouponButtonContainer,
  CouponParagraphContainer,
  MainContainerDiv,
  FormButtons,
  Trans,
};

export default Styled;
