import React, { useState, useEffect } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useCartDispatch, useCartState } from '../../context/CartContext';
import queryString from 'query-string';
import { omitBy, isEmpty } from 'lodash';
import useFetch from 'use-http';
import Container from '../../shared/Container';
import Loader from '../../shared/Loader';
import Card from '../../shared/Card';
import Page from '../../shared/Page';
import Filter from '../ServicesPage/Filter/Filter';
import Styled from '../ServicesPage/Services.styles';
import NoResults from '../../shared/NoResults/NoResults';
import _ from 'lodash';
import Modal from '../../shared/Modal';
import Button from '../../shared/Button';

import WorkplaceServices from '../../../__mock__/WorkplaceServices';
import InlineLoader from '../../shared/InlineLoader/InlineLoader';
import { exponeaCapture } from '../../hooks/exponeaCapture';
const USE_MOCKS = false;

const Combined = (props) => {
  const { categoryId } = useParams();
  const { search: urlParams, state } = useLocation();
  const options = queryString.parse(urlParams);
  const [cityId, setCityId] = useState(null);
  const dispatch = useCartDispatch();
  const [orderedServiceGroups, setOrderedServiceGroups] = useState();
  const [selectedDepartmentFilter, setSelectedDepartmentFilter] = useState();
  const [selectedServiceFilter, setSelectedServiceFilter] = useState();

  const history = useHistory();
  const {
    loading: loadingFilters,
    error,
    data: filters = [],
  } = useFetch(
    `/Services/CombinedServicesFilter?departmentid=${selectedDepartmentFilter}&holter=${
      options?.holter === undefined ? false : options?.holter
    }`,
    [
      selectedDepartmentFilter,
      history?.location?.state?.services,
      options?.holter,
    ],
  );

  const [initialFilters, setInitialFilters] = useState(filters);

  const { get, loading: loadingCombined } = useFetch(
    `/Services/CombinedServices`,
  );

  const { get: getServiceGroups, loading: getServiceGroupsLoading } = useFetch(
    '/Services/CombinedServicesGroups',
  );

  const { get: getGroupedCombinedServices, loading: groupedloadingServices } =
    useFetch('/Services/GroupedCombinedServices');

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [moreLoading, setMoreLoading] = useState(false);
  const [isActive, setIsActive] = useState();
  const [serviceGroups, setServiceGroups] = useState();
  const [externalServiceTypeId, setExternalServiceTypeId] = useState();
  const [selectedServiceGroup, setSelectedServiceGroup] = useState();
  const [serviceGroupedServices, setServiceGroupedServices] = useState();
  const [loadingGroup, setLoadingGroup] = useState(false);
  const [expandSingleGroup, setExpandSingleGroup] = useState();
  const [resetGroups, setResetGroups] = useState(false);
  const [serviceTitle, setServiceTitle] = useState('Paslaugų paketai');
  const [serviceGroupsLoading, setServiceGroupsLoading] = useState(true);
  function changeActiveState(idx) {
    setIsActive((state) => [...state]);
  }

  useEffect(() => {
    if (history?.location?.state?.services === 'holter') {
      setServiceTitle(history?.location?.state?.serviceTitle);
    }
  }, [history?.location?.state?.services]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (history.location.search !== '?' && history.location.search !== '') {
      setLoadingGroup(true);
      get(
        history.location.search !== '?' && history.location.search !== ''
          ? '?' +
              queryString.stringify({
                ...queryString.parse(history.location.search),
              })
          : `&serviceGroupId=${selectedServiceGroup}
              )}`,
      ).then((data) => {
        setServiceGroupedServices(data);
        if (history.location.search !== '?' && history.location.search !== '') {
          setExpandSingleGroup(data);
        }
        setLoadingGroup(false);
      });
    } else if (
      history.location.search === '?' ||
      history.location.search === ''
    ) {
      setExpandSingleGroup(null);
      setResetGroups(true);
    }
  }, [get, history.location.search]);

  useEffect(() => {
    if (!loadingFilters) {
      setServiceGroupsLoading(true);
      getServiceGroups(
        `?departmentId=${selectedDepartmentFilter}&serviceId=${selectedServiceFilter}&holter=${
          options?.holter == undefined ? false : options?.holter
        }`,
      ).then((res) => {
        setServiceGroups(res);
        setServiceGroupsLoading(false);
      });
    }
  }, [
    externalServiceTypeId,
    getServiceGroups,
    resetGroups,
    selectedDepartmentFilter,
    selectedServiceFilter,
    loadingFilters,
    options?.holter,
  ]);

  useEffect(() => {
    if (selectedServiceGroup) {
      setLoadingGroup(true);

      getGroupedCombinedServices(
        '?' +
          queryString.stringify({
            ...queryString.parse(history.location.search),
            groupId: isActive.find((x) => x.isActive).externalId,
          }),
      ).then((data) => {
        setServiceGroupedServices(data);
        setLoadingGroup(false);
      });
    }
  }, [getGroupedCombinedServices, selectedServiceGroup]);

  useEffect(() => {
    let exponea = JSON.parse(sessionStorage.getItem('exponeaTrack') || '[]');
    if (exponea.length > 0) {
      exponea.push({ name: 'Registration status', value: 'Cancelled' });
      exponeaCapture(exponea, 'booking_item');
      sessionStorage.removeItem('exponeaTrack');
    }
    exponeaCapture(
      [
        { name: 'category_1', value: 'Paslaugų paketai' },
        { name: 'category_id', value: 3 },
      ],
      'view_item',
    );
  }, []);

  useEffect(() => {
    const searchWithoutQuestionMark = history.location.search.substring(1);
    const paramPairs = searchWithoutQuestionMark.split('&');
    const parsedParams = {};
    paramPairs.forEach((paramPair) => {
      const [paramName, paramValue] = paramPair.split('=');
      parsedParams[paramName] = paramValue;
    });
    setSelectedDepartmentFilter(parsedParams.departmentId);
    setSelectedServiceFilter(parsedParams.serviceId);
    if (parsedParams?.serviceId) {
      updateFilters({ value: parsedParams.serviceId, name: 'serviceId' });
    } else {
      updateFilters({ value: '', name: 'serviceId' });
    }
    if (parsedParams?.departmentId) {
      updateFilters({ value: parsedParams.departmentId, name: 'departmentId' });
    } else {
      updateFilters({ value: '', name: 'departmentId' });
    }
  }, [history.location.search]);

  useEffect(() => {
    delete filters['isPsdf'];
    setInitialFilters(filters);
  }, [filters]);

  function updateFilters({ value, name }) {
    const q = queryString.stringify(
      omitBy({ ...options, [name]: value }, isEmpty),
    );
    if (name === 'departmentId' && value) {
      setSelectedDepartmentFilter(value);
    } else if (name === 'departmentId' && !value) {
      setSelectedDepartmentFilter(undefined);
    }

    if (name === 'serviceId' && value) {
      setSelectedServiceFilter(value);
    } else if (name === 'serviceId' && !value) {
      setSelectedServiceFilter(undefined);
    }

    history.replace({ search: `?${q}` });
  }

  useEffect(() => {
    const active = [];
    let servGroups = [];
    if (serviceGroups?.length > 0) {
      if (resetGroups || !expandSingleGroup) {
        setResetGroups(false);
        if (serviceGroups?.length === 1) {
          active.push({
            externalId: serviceGroups[0].externalId,
            isActive: true,
          });
          servGroups = serviceGroups;
          setSelectedServiceGroup(serviceGroups[0].externalId);
        } else {
          const ordered = _.orderBy(serviceGroups, ['name'], ['asc']);

          servGroups = ordered;
          ordered.map(({ externalId, name }, i) => {
            active.push({ externalId: externalId, isActive: false });
          });
        }
        setIsActive(active);
      } else if (expandSingleGroup && !resetGroups) {
        servGroups = serviceGroups;

        if (servGroups.length === 1) {
          const singleGroup = [
            {
              externalId: servGroups[0]?.externalId,
              isActive: true,
            },
          ];
          setIsActive(singleGroup);
          setServiceGroupedServices(expandSingleGroup);
          setSelectedServiceGroup(singleGroup[0].externalId);
        } else {
          const activeArr = [];
          servGroups.map(({ externalId, name }, i) => {
            activeArr.push({ externalId: externalId, isActive: false });
          });
          setIsActive(activeArr);
        }
      }

      var orderedGroups = _.orderBy(servGroups, ['name'], ['asc']);
      setOrderedServiceGroups(orderedGroups);
    }
  }, [serviceGroups, expandSingleGroup, resetGroups]);

  function getTitle() {
    return serviceTitle;
  }

  return (
    <Page
      error={(!data && !serviceGroups && !loadingFilters) || error}
      loading={loadingFilters}
    >
      <Container marginTop={true}>
        <Page.Title backUrl={`/`} greyTheme={true} smallHeight={true}>
          {' '}
          {getTitle()}
        </Page.Title>
      </Container>
      <Filter
        value={options}
        onChange={updateFilters}
        isLoading={loadingFilters}
        filters={initialFilters}
        onClear={() => history.push({ search: '' })}
        category={categoryId}
        isGrid={categoryId === '1'}
        enableSorting={false}
      />

      <Container>
        {serviceGroupsLoading ? (
          <Styled.ServicesLoader>
            <Loader />
          </Styled.ServicesLoader>
        ) : (
          <>
            <>
              {orderedServiceGroups !== undefined &&
              orderedServiceGroups?.length > 0 ? (
                orderedServiceGroups?.length === isActive.length &&
                orderedServiceGroups?.map(({ externalId, name }, idx) => {
                  return (
                    <>
                      <Styled.PricesAccordionTitle
                        key={idx}
                        topMargin={true}
                        onClick={() => {
                          if (!loadingGroup) {
                            isActive.find(
                              (r) => r.externalId == externalId,
                            ).isActive = !isActive.find(
                              (r) => r.externalId == externalId,
                            ).isActive;

                            isActive.forEach((s) => {
                              if (s.externalId != externalId) {
                                s.isActive = false;
                              }
                            });

                            if (
                              isActive.find((r) => r.externalId == externalId)
                                .isActive
                            ) {
                              if (externalId != selectedServiceGroup) {
                                // setServiceGroupedServices([]);
                              }
                              setSelectedServiceGroup(externalId);
                            }
                            changeActiveState(externalId);
                          }
                        }}
                      >
                        <div>{name}</div>

                        <div>
                          {isActive.find((r) => r.externalId == externalId)
                            .isActive
                            ? '-'
                            : '+'}
                        </div>
                      </Styled.PricesAccordionTitle>

                      {isActive.find((r) => r.externalId == externalId)
                        .isActive && (
                        <Styled.PricesAccordionContent key={idx + 1}>
                          {loadingGroup && (
                            <InlineLoader light={true}></InlineLoader>
                          )}
                          <Card.List noTopMargin={true}>
                            {_.orderBy(serviceGroupedServices).map(
                              (service, i) => {
                                return (
                                  <Card
                                    isComplex={true}
                                    key={i}
                                    service={service.service}
                                    categoryId={3}
                                    backUrl={urlParams}
                                    noRegistrationService={false}
                                    complexPrices={service.prices}
                                    selectedFilterDepartment={
                                      selectedDepartmentFilter
                                    }
                                  />
                                );
                              },
                            )}
                          </Card.List>
                        </Styled.PricesAccordionContent>
                      )}
                    </>
                  );
                })
              ) : (
                <NoResults>Paieškos rezultatų nėra</NoResults>
              )}
              {moreLoading && (
                <Styled.ServicesLoader>
                  <Loader />
                </Styled.ServicesLoader>
              )}
            </>
          </>
        )}
      </Container>
    </Page>
  );
};

export default Combined;
