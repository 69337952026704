import { useCallback } from 'react';
import useFetch from 'use-http';
import { useCartState, useCartDispatch } from '../context/CartContext';
import { useHistory } from 'react-router-dom';

function useTimeslotApi() {
  const history = useHistory();
  const { request: timeRequest } = useFetch(`/TimeSlots`);
  const dispatch = useCartDispatch();
  const { registrationServices, inActionTimeSlot } = useCartState();

  const removeServices = useCallback(() => {
    if (!registrationServices.length) {
      history.push('/');
    }
    timeRequest.delete(`/Reservation/${inActionTimeSlot}`);
    dispatch({
      type: 'RESET_CART',
    });
    history.push('/');
  }, [
    dispatch,
    history,
    registrationServices.length,
    inActionTimeSlot,
    timeRequest,
  ]);

  function extendTime() {
    const timeSlotsIds = [];

    registrationServices.forEach((s) => timeSlotsIds.push(s.timeSlotId));
    inActionTimeSlot && timeSlotsIds.push(inActionTimeSlot);

    timeRequest.post('/Reservation', { timeSlotsIds }).then(() => {
      dispatch({ type: 'RESET_TIMER' });
    });
  }

  return {
    removeServices,
    extendTime,
    loading: timeRequest.loading,
  };
}

export default useTimeslotApi;
