import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { ReactComponent as ErrorIcon } from '../../../assets/icons/error-circle.svg';
import { ReactComponent as SuccessIcon } from '../../../assets/icons/success-circle.svg';
import Modal from '../Modal';
import Button from '../Button';
import { useUserState } from '../../context/UserContext';
import { useHistory } from 'react-router-dom';
import useFetch from 'use-http';
import { useCartState, useCartDispatch } from '../../context/CartContext';
import Loader from '../Loader';
import { urlEncode } from '@sentry/utils';
import { useSettingsState } from '../../context/SettingsContext';
import { exponeaCapture } from '../../hooks/exponeaCapture';
const PaymentRedirectModal = () => {
  const errorMatchWithMsg = useRouteMatch(
    '/payment/error/:description',
  )?.isExact;
  const errorMatch = useRouteMatch('/payment/error')?.isExact;

  const errorMatchWithMsgParams = useRouteMatch(
    '/payment/error/:description',
  )?.params;
  const successMatch = useRouteMatch('/payment/accept/:orderId')?.isExact;
  const successMatchParams = useRouteMatch('/payment/accept/:orderId')?.params;
  const psdfSuccessMatch = useRouteMatch('/payment/psdfAccept')?.isExact;
  const psdfWithAdditionalPaymentSuccessMatch = useRouteMatch(
    '/payment/psdfWithAdditionalPaymentAccept',
  );
  const manualSuccess = useRouteMatch('/success/manual')?.isExact;
  const manualSecondSuccess = useRouteMatch('/success/manual/other')?.isExact;
  const ctxrmSuccessMatch = useRouteMatch(
    '/payment/ctxrmAccept/:orderId',
  )?.isExact;
  const ctxrmSuccessParams = useRouteMatch(
    '/payment/ctxrmAccept/:orderId',
  )?.params;
  const psdferrorMatch = useRouteMatch('/payment/psdfError')?.isExact;
  const couponSuccessMatch = useRouteMatch('/payment/couponAccept')?.isExact;
  const complexServicesPurchaseErrorMatch = useRouteMatch(
    '/complex-payment/error',
  )?.isExact;

  const [couponFullSuccess, setCouponFullSuccess] = useState(false);
  const [couponSuccess, setCouponSuccess] = useState(false);
  const [couponTitle, setCouponTitle] = useState('');
  const [couponComment, setCouponComment] = useState('');
  const [couponValidTo, setCouponValidTo] = useState('');
  const [couponPrice, setCouponPrice] = useState();
  const [couponFile, setCouponFile] = useState();
  const [couponPending, setCouponPending] = useState();
  const [couponOrderId, setCouponOrderId] = useState();
  const [errorWithMsg, setErrorWithMsg] = useState(false);
  const { phoneNumber } = useSettingsState();

  const [couponSuccessPending, setCouponSuccessPending] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [psdfSuccess, setPsdfSuccess] = useState(false);
  const [
    psdfWithAdditionalPaymentSuccess,
    setPsdfWithAdditionalPaymentSuccess,
  ] = useState(false);
  const [ctxrmSuccess, setCtxrmSuccess] = useState(false);
  const [manualSuccessShow, setManualSuccessShow] = useState(false);
  const [psdfError, setPsdfError] = useState(false);
  const [complexServicesPurchaseError, setComplexServicesPurchaseError] =
    useState(false);
  const [manualMessage, setManualMessage] = useState(false);
  const [couponError, setCouponError] = useState(false);
  const [paidCancelledModal, setPaidCancelledModal] = useState(false);
  const userState = useUserState();
  const history = useHistory();
  const dispatch = useCartDispatch();
  const cartState = useCartState();
  const [errorDescription, setErrorDescription] = useState('');
  const { get: getResetHistory, response: getResetHistoryResponse } = useFetch(
    `/UserAccount/Visits/Reset`,
  );

  const { request } = useFetch(`/RegistrationManagement/RegistrationStatus`);
  const { request: couponRequest } = useFetch(`/CouponManagement/CouponStatus`);

  useEffect(() => {
    if (ctxrmSuccessMatch && ctxrmSuccessParams?.orderId) {
      request
        .get(`?registrationId=${ctxrmSuccessParams?.orderId}`)
        .then((res) => {
          if (res.paymentStatus === 'Cancelled' && res.status === 'Cancelled') {
            setPaidCancelledModal(true);
            setSuccess(false);
            setEnabled(true);
          }
        });
    }
  }, [request, ctxrmSuccessMatch, ctxrmSuccessParams]);

  useEffect(() => {
    if (successMatch && successMatchParams?.orderId) {
      request
        .get(`?registrationId=${successMatchParams?.orderId}`)
        .then((res) => {
          if (res.paymentStatus === 'Cancelled' && res.status === 'Cancelled') {
            setPaidCancelledModal(true);
            setSuccess(false);
            setEnabled(true);
          }
        });
    }
  }, [request, successMatch, successMatchParams]);

  useEffect(() => {
    if (errorMatchWithMsg && errorMatchWithMsgParams?.description) {
      setErrorDescription(
        decodeURIComponent(errorMatchWithMsgParams?.description).replaceAll(
          '+',
          ' ',
        ),
      );
    }
  }, [errorMatchWithMsg, errorMatchWithMsgParams]);

  const {
    post: cancelRegistrationRequestSend,
    response: cancelRegistrationResponse,
  } = useFetch(`/RegistrationManagement/Registrations/Cancel`);

  const handleOnClose = () => {
    setEnabled(false);
    setSuccess(false);
    if (userState.isLoggedIn && enabled) {
      history.push('/account/registrations');
    }
  };

  const handleOnCloseCouponFull = () => {
    setEnabled(false);
    setCouponFullSuccess(false);
  };

  const handleOnCloseCoupon = () => {
    setEnabled(false);
    setCouponSuccess(false);
    // if (userState.isLoggedIn && enabled) {
    // }
  };

  const handleOnClosePendingCoupon = () => {
    setEnabled(false);
    setCouponSuccessPending(false);
  };

  const handleOnCloseManual = () => {
    setEnabled(false);
    setManualSuccessShow(false);
  };

  const handleOnClosePsdf = () => {
    setPsdfSuccess(false);
    setEnabled(false);
    if (userState.isLoggedIn && enabled) {
      history.push('/account/registrations');
    }
  };

  const handleOnClosePsdfWithAdditionalPayment = () => {
    setPsdfWithAdditionalPaymentSuccess(false);
    setEnabled(false);
    if (userState.isLoggedIn && enabled) {
      history.push('/account/registrations');
    }
  };

  const handleOnCloseCtxrm = () => {
    setCtxrmSuccess(false);
    setEnabled(false);
    if (userState.isLoggedIn && enabled) {
      history.push('/account/registrations');
    }
  };

  const handleOnCloseComplexServicesPurchaseError = () => {
    setEnabled(false);
    setComplexServicesPurchaseError(false);
  };

  const forceResetHistory = async () => {
    await getResetHistory();
  };

  const logResultInExponea = (status) => {
    let exponea = JSON.parse(sessionStorage.getItem('exponeaTrack') || '[]');
    exponea.push({ name: 'Registration status', value: status });

    let bookingStatus = null;
    if (status === 'Success PSDF') {
      bookingStatus = 'Booked';
    } else if (status === 'Success') {
      bookingStatus = 'Booked & Paid';
    } else {
      bookingStatus = 'Cancelled';
    }
    exponea.push({
      name: 'booking_stage',
      value: bookingStatus,
    });
    exponeaCapture(exponea, 'booking');
    sessionStorage.removeItem('exponeaTrack');
  };

  const modals = [
    {
      type: 'success',
      title: 'Užsakymas priimtas',
      text: (
        <>
          <p>
            Jūsų mokėjimo nurodymas priimtas. Iš banko gavus atsakymą apie
            apmokėjimą būsite informuoti el. paštu.
          </p>
          <p>
            Jūsų nurodytu el. paštu išsiuntėme atskirą registracijos
            patvirtinimą kiekvienai įsigytai paslaugai.
          </p>
          <p>
            Atėjus konsultacijos laikui su Jumis susisieksime registracijos metu
            nurodytu telefono numeriu.
          </p>
        </>
      ),
      onClose: () => {
        dispatch({
          type: 'RESET_CART',
        });
        handleOnClose();
      },
      visible: success,
    },
    {
      type: 'success',
      title: 'Užsakymas priimtas',
      text: (
        <>
          <p>Jūsų mokėjimo nurodymas priimtas.</p>
          <p>
            Jūsų nurodytu el. paštu išsiuntėme atskirą registracijos
            patvirtinimą kiekvienai įsigytai paslaugai.
          </p>
          <p>
            Atėjus konsultacijos laikui su Jumis susisieksime registracijos metu
            nurodytu telefono numeriu.
          </p>
        </>
      ),
      onClose: () => handleOnCloseCouponFull(),
      visible: couponFullSuccess,
    },
    {
      type: 'error',
      title: 'Užsakymo apmokėjimas',
      text: (
        <>
          <p>
            Gavome Jūsų užsakymo apmokėjimą, tačiau užsakymas buvo atšauktas
            ankščiau.
          </p>
          <p>
            Paskambinkite {phoneNumber} ir mes padėsime išspręsti šią situaciją.
          </p>
        </>
      ),
      onClose: () => {
        setPaidCancelledModal(false);
      },
      visible: paidCancelledModal,
    },
    {
      type: 'error',
      title: 'Registracija atšaukta',
      text: 'Prašome užsiregistruoti iš naujo ir pakartoti mokėjimą.',
      onClose: () => setError(false),
      visible: error,
    },

    {
      type: 'error',
      title: 'Mokėjimo atlikti nepavyko',
      text: (
        <>
          <p>{errorDescription}</p>
          <p>
            Pabandykite pakartoti iš naujo arba skambinkite telefonu{' '}
            {phoneNumber}
          </p>
        </>
      ),
      onClose: () => {
        setErrorWithMsg(false);
      },
      visible: errorWithMsg,
    },
    {
      type: 'success',
      subtype: 'psdfSuccess',
      title: 'Registracija priimta',
      text: (
        <>
          <p>
            Jūsų prašymas registracijai priimtas. Kai tik Jūsų registracija bus
            patvirtinta, būsite informuoti SMS žinute. Informuojame, kad
            registracijos patvirtinimas gali užtrukti iki 24 val.
          </p>
          <p>
            Jeigu pasirinktai paslaugai reikalingas siuntimas, jis turi būti
            prisegtas pdf formatu ar išrašytas elektroninis, kitaip registracija
            bus atšaukta. Apie atšaukimą būsite informuoti SMS žinute.
          </p>
          <p>Dėkojame, kad renkatės Affidea.</p>
        </>
      ),
      onClose: () => {
        handleOnClosePsdf();
      },
      visible: psdfSuccess,
    },
    {
      type: 'success',
      subtype: 'psdfWithAdditionalPaymentSuccess',
      title: 'Registracija priimta',
      text: (
        <>
          <p>
            Jūsų prašymas registracijai ir mokėjimo nurodymas priimtas. Iš banko
            gavus atsakymą apie apmokėjimą būsite informuoti el. paštu. Kai tik
            Jūsų registracija bus patvirtinta, būsite informuoti SMS žinute.
            Informuojame, kad registracijos patvirtinimas gali užtrukti iki 24
            val.
          </p>
          <p>
            Jeigu pasirinktai paslaugai reikalingas siuntimas, jis turi būti
            prisegtas pdf formatu ar išrašytas elektroninis, kitaip registracija
            bus atšaukta. Atšaukus registraciją mokėjimas grąžinamas per 14 d.d.
          </p>
          <p>Dėkojame, kad renkatės Affidea.</p>
        </>
      ),
      onClose: () => {
        handleOnClosePsdfWithAdditionalPayment();
      },
      visible: psdfWithAdditionalPaymentSuccess,
    },
    {
      type: 'success',
      subtype: 'ctxrmSuccess',
      title: 'Registracija priimta',
      text: (
        <>
          <p>
            Jūsų mokėjimo nurodymas priimtas. Iš banko gavus atsakymą apie
            apmokėjimą būsite informuoti el. paštu.
          </p>
          <p>
            Jūsų nurodytu el. paštu išsiuntėme atskirą registracijos
            patvirtinimą kiekvienai įsigytai paslaugai.
          </p>
          <p>
            Atėjus konsultacijos laikui su Jumis susisieksime registracijos metu
            nurodytu telefono numeriu.
          </p>
          <p>
            Pasirinktai paslaugai reikalingas siuntimas, jis turi būti prisegtas
            pdf formatu ar išrašytas elektroninis, kitaip registracija bus
            atšaukta. Apie atšaukimą būsite informuoti SMS žinute.
          </p>
        </>
      ),
      onClose: () => handleOnCloseCtxrm(),
      visible: ctxrmSuccess,
    },
    {
      type: 'error',
      title: 'Registracijos atlikti nepavyko',
      text: 'Prašome užsiregistruoti iš naujo.',
      onClose: () => {
        setPsdfError(false);
      },
      visible: psdfError,
    },
    {
      type: 'success',
      title: 'Jūs sėkmingai įsigijote dovanų kuponą.',
      text: (
        <>
          <p>{couponTitle}</p>
          {couponComment && <p>{couponComment}</p>}
          <p>{couponValidTo}</p>
        </>
      ),
      onClose: () => handleOnCloseCoupon(),
      visible: couponSuccess,
    },
    {
      type: 'pending',
      title: 'Laukiamas jūsų apmokėjimo patvirtinimas',
      text: <></>,
      onClose: () => handleOnClosePendingCoupon(),
      visible: couponSuccessPending,
    },
    {
      type: 'success',
      title: 'Jūs sėkmingai įsigijote dovanų kuponą.',
      text: (
        <>
          <p>{couponTitle}</p>
          {couponComment && <p>{couponComment}</p>}
          <p>{couponValidTo}</p>
        </>
      ),
      onClose: () => handleOnCloseCoupon(),
      visible: couponSuccess,
    },
    {
      type: 'success',
      title: 'Registracija priimta',
      text: (
        <>
          <p>{manualMessage}</p>
        </>
      ),
      onClose: () => handleOnCloseManual(),
      visible: manualSuccessShow,
    },
    {
      type: 'error',
      title: 'Įvyko nenumatyta klaida',
      text: (
        <>
          <p>Paslaugų paketų įsigyti nepavyko. Bandykite dar kartą.</p>
        </>
      ),
      onClose: () => {
        handleOnCloseComplexServicesPurchaseError();
      },
      visible: complexServicesPurchaseError,
    },
  ];

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  useEffect(() => {
    if (cartState.registrationId && errorMatch) {
      cancelRegistrationRequestSend('', {
        cancelationToken: cartState.registrationId,
      });

      dispatch({
        type: 'RESET_REGISTRATION_ID',
      });
    }
  }, [cartState.registrationId, dispatch, errorMatch]);

  useEffect(() => {
    if (errorMatch) {
      logResultInExponea('Cancelled');
      setError(true);
    }
  }, [errorMatch]);

  useEffect(() => {
    if (errorMatchWithMsg) {
      logResultInExponea('Cancelled');
      setErrorWithMsg(true);
    }
  }, [errorMatchWithMsg]);

  useEffect(() => {
    if (successMatch && !paidCancelledModal) {
      dispatch({
        type: 'RESET_REGISTRATION_ID',
      });
      logResultInExponea('Success');
      setSuccess(true);
      setEnabled(true);
      if (userState.isLoggedIn) {
        setSubmitting(true);
        forceResetHistory();
        sleep(3500).then(() => {
          setSubmitting(false);
        });
      }
    }
  }, [paidCancelledModal, successMatch]);

  useEffect(() => {
    if (psdfWithAdditionalPaymentSuccessMatch) {
      dispatch({
        type: 'RESET_REGISTRATION_ID',
      });
      logResultInExponea('Success');
      setPsdfWithAdditionalPaymentSuccess(true);
      setEnabled(true);
      if (userState.isLoggedIn) {
        setSubmitting(true);

        forceResetHistory();
        sleep(3500).then(() => {
          setSubmitting(false);
        });
      }
    }
  }, [psdfWithAdditionalPaymentSuccessMatch]);

  useEffect(() => {
    if (psdfSuccessMatch) {
      logResultInExponea('Success PSDF');
      dispatch({
        type: 'RESET_REGISTRATION_ID',
      });
      setPsdfSuccess(true);
      setEnabled(true);
      if (userState.isLoggedIn) {
        setSubmitting(true);

        forceResetHistory();
        sleep(3500).then(() => {
          setSubmitting(false);
        });
      }
    }
  }, [psdfSuccessMatch]);

  useEffect(() => {
    if (ctxrmSuccessMatch) {
      dispatch({
        type: 'RESET_REGISTRATION_ID',
      });
      setCtxrmSuccess(true);
      logResultInExponea('Success');
      setEnabled(true);
      if (userState.isLoggedIn) {
        setSubmitting(true);

        forceResetHistory();
        sleep(3500).then(() => {
          setSubmitting(false);
        });
      }
    }
  }, [ctxrmSuccessMatch]);

  useEffect(() => {
    if (psdferrorMatch) {
      logResultInExponea('Failed');
      setPsdfError(true);
    }
  }, [psdferrorMatch]);

  useEffect(() => {
    if (manualSuccess) {
      logResultInExponea('Success');
      setManualSuccessShow(true);
      setManualMessage(
        'Jūsų mokėjimas gautas. Registracijos būseną galite peržiūrėti savo paskyroje',
      );
    }
  }, [manualSuccess]);

  useEffect(() => {
    if (manualSecondSuccess) {
      logResultInExponea('Cancelled');
      setManualSuccessShow(true);
      setManualMessage(
        'Registracija atšaukta. Registracijos būseną galite peržiūrėti savo paskyroje',
      );
    }
  }, [manualSecondSuccess]);

  useEffect(() => {
    if (couponSuccessMatch) {
      dispatch({
        type: 'RESET_REGISTRATION_ID',
      });
      logResultInExponea('Success');
      setCouponFullSuccess(true);
      setEnabled(true);
      if (userState.isLoggedIn) {
        setSubmitting(true);

        forceResetHistory();
        sleep(3500).then(() => {
          setSubmitting(false);
        });
      }
    }
  }, [couponSuccessMatch]);

  useEffect(() => {
    if (complexServicesPurchaseErrorMatch) {
      logResultInExponea('Failed');
      setComplexServicesPurchaseError(true);
    }
  }, [complexServicesPurchaseErrorMatch]);

  return (
    <>
      {modals.map((modal, i) => (
        <Modal
          key={i}
          visible={modal.visible}
          onClose={modal.onClose}
          loading={submitting}
          showClose={couponPending || !submitting}
        >
          <Modal.Alert type={modal.type}>
            {modal.title}
          </Modal.Alert>
          <Modal.TextContent subtype={modal.subtype}>
            {modal.text}
          </Modal.TextContent>
          <Modal.Actions>
            <Button
              disabled={submitting}
              loading={submitting}
              size="large"
              onClick={modal.onClose}
            >
              Uždaryti
            </Button>
          </Modal.Actions>
        </Modal>
      ))}
    </>
  );
};

export default PaymentRedirectModal;
