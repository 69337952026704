export const breakpoints = {
  sm: 767,
  md: 979,
  xsImage: 650,
  xs: 500,
  xxs:350
};

export const mediaDown = (key) => {
  return (style) => `@media (max-width: ${breakpoints[key]}px) { ${style} }`;
};

export const mediaUp = (key) => {
  return (style) =>
    `@media (min-width: ${breakpoints[key] + 1}px) { ${style} }`;
};
