import {
  addWeeks,
  eachDayOfInterval,
  format as dateFnsFormat,
  isSameDay,
  lastDayOfWeek,
  parseISO,
  startOfWeek,
} from 'date-fns';
import { lt as locale } from 'date-fns/locale';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow.svg';
import { ReactComponent as ClockIcon } from '../../../assets/icons/clock_gr.svg';
import { ReactComponent as TlkIcon } from '../../../assets/icons/tlk.svg';
import Form from '../Form';
import Loader from '../Loader';
import { Money } from '../Money';
import Styled from './Calendar.styles';

const Calendar = ({
  filter,
  dataRequest,
  onChange,
  selected,
  error,
  refresh,
  psdfService,
  hasNearestPsdfDate,
  defaultData = new Date(),
  widget,
  extraPrice,
  servicePrice,
  refreshOfFilter,
  isHalfMrt,
  workplaceId,
  service,
  psdfPrice,
}) => {
  const { t } = useTranslation();
  const format = (date, formatStr = 'PP') =>
    dateFnsFormat(date, formatStr, { locale });
  const [loading, setLoading] = useState(true);
  const [day, setDay] = useState(new Date(defaultData));
  const [currentDay, setCurrentDay] = useState();
  const [timeSlots, setTimeSlots] = useState([]);
  const [i, setI] = useState(false);
  const weekOptions = { weekStartsOn: 1 };
  let weekStart = startOfWeek(day, weekOptions);
  let endOfWeek = lastDayOfWeek(day, weekOptions);

  const weekStartDate = format(weekStart, 'yyyy-MM-dd');
  const endOfWeekDate = format(endOfWeek, 'yyyy-MM-dd');

  const weekDays = eachDayOfInterval({ start: weekStart, end: endOfWeek });

  useEffect(() => {
    if (filter.split('?')[1]) setDay(new Date(filter.split('?')[1]));
  }, [filter]);

  // useEffect(() => {
  //   if (refresh) {
  //     const startDate = weekStartDate;
  //     dataRequest(startDate, endOfWeekDate, filter.split('?')[0])
  //       .then((data) => {
  //         if (data) {
  //           setTimeSlots(data);
  //         }
  //         setLoading(false);
  //       })
  //       .catch((error) => {});
  //   }
  // }, [refresh]);

  useEffect(() => {
    setLoading(true);
    const startDate = weekStartDate;
    dataRequest(startDate, endOfWeekDate, filter.split('?')[0])
      .then((data) => {
        if (data) {
          setTimeSlots(data);
          setCurrentDay(day);
        }
        setLoading(false);
      })
      .catch((error) => {});
  }, [i, dataRequest, weekStartDate, endOfWeekDate, day]);

  const getTimeSlots = useCallback(() => {
    var slots = [];
    weekDays.forEach((weekDay) => {
      if (Array.isArray(timeSlots)) {
        let daySlots = timeSlots.filter(
          (time) =>
            isSameDay(parseISO(time.startDate), weekDay) &&
            (time.statusName === 'Available' ||
              (time.duplicatedTimeSlotId !== null
                ? selected === time.duplicatedTimeSlotId
                : selected === time.timeSlotId)),
        );
        if (widget) {
          const filteredSlots = daySlots.filter(
            (ts) =>
              ts.statusName === 'Available' &&
              !ts.isReserved &&
              ts.visitStatus === null,
          );
          daySlots = filteredSlots.slice(0, 3);
        }

        // daySlots =  daySlots.filter(slot=>!slot.isPaid)

        // Exclude extra time slots for whom price is not set yet.
        // daySlots = daySlots.filter(
        //   (ts) =>
        //     !(ts.tags.includes(process.env.REACT_APP_EXTRA_TAG) && !extraPrice),
        // );

        slots.push(daySlots);
      }
    });

    const maxLenght = slots.reduce(
      (r, s) => (r.length > s.length ? r : s),
      0,
    ).length;

    let extendedSlots = slots.map((slot) => [
      ...slot,
      ...new Array(maxLenght - slot.length).fill(0),
    ]);

    if (isHalfMrt) {
      return extendedSlots.filter((slot) => !slot.isPaid);
    }
    return extendedSlots;
  }, [selected, timeSlots, weekDays]);

  const timeSlotTime = (timeSlotDate) => {
    var moment = require('moment-timezone');
    const convertedTime = moment(timeSlotDate)
      .tz('Europe/Vilnius')
      .format('HH:mm');
    return convertedTime;
  };

  function changeWeek(amount) {
    setLoading(true);
    setDay(addWeeks(day, amount));
    setCurrentDay(addWeeks(day, amount));
  }

  function showBackButton() {
    return new Date() < new Date(weekStartDate);
  }

  const isEmpty = !getTimeSlots().filter((arr) => !!arr.length).length;

  return (
    <>
      <Styled.Calendar widget={widget}>
        <Styled.Controls>
          {showBackButton() && !widget && (
            <Styled.Arrow left onClick={() => changeWeek(-1)}>
              <ArrowIcon />
            </Styled.Arrow>
          )}

          <Styled.Title>
            {`${format(weekStart, 'yyyy MMMM d')}-${format(endOfWeek, 'd')} d.`}
          </Styled.Title>
          {!widget && (
            <Styled.Arrow onClick={() => changeWeek(1)}>
              <ArrowIcon />
            </Styled.Arrow>
          )}
        </Styled.Controls>

        <Styled.Body>
          <Styled.Header>
            {weekDays.map((day, i) => (
              <Styled.Day key={i}>
                {format(day, 'd')}
                <small>{format(day, 'ccc')}</small>
              </Styled.Day>
            ))}
          </Styled.Header>
          <Styled.Columns>
            {loading ? (
              <Styled.NoResults>
                <Loader inline />
              </Styled.NoResults>
            ) : (
              <>
                {isEmpty ? (
                  <Styled.NoResults>
                    <Styled.NoResultsMessage>
                      <ClockIcon />
                      {filter.split('?')[0] === 'tlk' &&
                      (!psdfService || !hasNearestPsdfDate)
                        ? 'Laisvų laikų PSDF kompensuojamoms paslaugoms nėra'
                        : t('validation.calendarNoAvailableTime')}
                    </Styled.NoResultsMessage>
                  </Styled.NoResults>
                ) : (
                  getTimeSlots().map((column, i) => (
                    <Styled.Column key={i}>
                      {column.map((item, ii) => (
                        <Styled.Cell key={ii}>
                          {item ? (
                            <Styled.Slot
                              isAdditional={item.isExtra}
                              is3T={psdfPrice !== 0}
                              isPaid={!item.isPaid && !item.isExtra}
                              isTaken={
                                item.isReserved || item.visitStatus !== null
                              }
                              price={item.isExtra || item.isPaid}
                              selected={
                                item.duplicatedTimeSlotId === null
                                  ? selected === item.timeSlotId
                                  : selected === item.duplicatedTimeSlotId
                              }
                              onClick={() => {
                                if (
                                  (!item.isReserved &&
                                    item.visitStatus === null) ||
                                  item.visitStatus === ''
                                ) {
                                  onChange(item);
                                }
                              }}
                            >
                              {!item.isPaid &&
                                !item.isExtra &&
                                psdfPrice !== 0 && (
                                  <React.Fragment>
                                    <Styled.AdditionalPayment>
                                      <Money amount={psdfPrice}></Money>
                                    </Styled.AdditionalPayment>
                                    <Styled.TlkIconSvg>
                                      <TlkIcon />
                                    </Styled.TlkIconSvg>
                                  </React.Fragment>
                                )}

                              {(item.isExtra || item.isPaid) && (
                                <Styled.ExtraPrice extra={item.isExtra}>
                                  <Money
                                    amount={
                                      (item.isExtra
                                        ? extraPrice
                                        : servicePrice) +
                                      item.additionalServicesPrice
                                    }
                                  ></Money>
                                </Styled.ExtraPrice>
                              )}

                              {timeSlotTime(item.startDate)}
                            </Styled.Slot>
                          ) : (
                            <Styled.SlotSpacer />
                          )}
                        </Styled.Cell>
                      ))}
                    </Styled.Column>
                  ))
                )}
              </>
            )}
          </Styled.Columns>
        </Styled.Body>
      </Styled.Calendar>
      {error && <Form.Error>{error}</Form.Error>}
    </>
  );
};

Calendar.propTypes = {
  dataRequest: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default Calendar;
